import "../styles/global.scss";
import React, { useEffect, useState } from "react";
import styles from "./chart.module.scss";
import { Chart as GoogleChart } from "react-google-charts";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { serverUrl } from "./editor/utility";

const chartColorPalette = [
  // purple
  "#714cfd",
  // green
  "#06D6A0",
  // blue
  "#30c5ff",
];

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function Chart() {
  const [data, setData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  let dataFetched = false;

  useEffect(() => {
    async function fetchChartData() {
      const oid = searchParams.get("oid");
      const p5 = searchParams.get("p5");
      console.log(`--- Fetching data for organisation ID: ${oid}`);

      const response = await axios.post(`${serverUrl}/wordTracker/chart/category`, {
        oid,
        p5,
      });

      if (response && response.data && response.data.chartData) {
        console.log(JSON.stringify(response.data.chartData));
        setData(response.data.chartData);
      } else {
        console.log(`Bad response`);
      }
    }

    if (dataFetched !== true) {
      console.log(`--- Fetching chart data!`);
      fetchChartData();
      dataFetched = true;
    }
  }, []);

  if (data) {
    return (
      <div>
        <GoogleChart
          chartType="ColumnChart"
          width="100%"
          height="200px"
          data={data}
          options={{
            legend: "none",
            // eslint-disable-next-line no-restricted-globals
            chartArea: { left: 30, top: 30, width: innerWidth - 50, height: 130 },
            colors: chartColorPalette,
          }}
          style={{}}
        />
      </div>
    );
  } else {
    return null;
  }
}

export default Chart;
