import "../styles/global.scss";
import onlineShop from "../images/online-shop.png";
import balloonsLots from "../images/balloonsLots.png";
import Feature from "../components/feature";
import Footer from "../components/footer";
import React from "react";
import styles from "./upgrade.module.scss";
import variables from "../styles/utils.module.scss";
import pippinLogo from "../images/pippin-logo-parrot.png";
import { getParameterByName } from "./utility";
import { useNavigate } from "react-router-dom";

function Upgraded() {
  let navigate = useNavigate();
  const code = getParameterByName("code");

  return (
    <div className="Landing">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
            <img src={onlineShop} className={styles.iconImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0, textAlign: "left" }}>
            Order Completed
          </h1>

          <h2 className={`${styles.subtitle} h5`} style={{ marginTop: 15 }}>
            Thank you for purchasing our Speech & Communication Course. <br />
            Please check your email for your unique activation link.
            {code}
          </h2>
        </div>
      </div>

      <Feature imagePath={balloonsLots} reverse={true} imageLarge={false}>
        <h3>Let's Get Started</h3>
        <p>
          We've emailed you information on how to download our app and access the course. This was
          sent to the email address you entered during the checkout. If you need any help please
          email support@pippinspeech.com.
        </p>
      </Feature>

      <Footer />
    </div>
  );
}

export default Upgraded;
