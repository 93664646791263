import styles from "./feature.module.scss";

const name = "Pippin";
export const siteTitle = "Pippin - Speech Support for Parents";

// A "Feature" Row, designed to be an image on one side and text on the other.
// reverse: Allows the order to be easily reversed
export default function Feature({ children, imagePath, reverse, imageLarge = false }) {
  return (
    <div
      className={styles.featureWrapper}
      style={
        reverse !== true
          ? {}
          : {
              backgroundColor: "#fafafa",
            }
      }
    >
      <section className={reverse !== true ? styles.feature : styles.featureReversed}>
        <div className={styles.featureLeft}>
          <img
            src={imagePath}
            className={imageLarge ? styles.featureImageLarge : styles.featureImage}
          />
        </div>
        <div className={styles.featureRight}>
          <div>{children}</div>
        </div>
      </section>
    </div>
  );
}
