import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "./utility";
import { useNavigate } from "react-router-dom";
import EditorWrapper from "../../components/editor/wrapper";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function SectionEditor() {
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  // We want the id itself to avoid conflicts with different versions of a section (draft, active, etc.)
  const id = getParameterByName("_id");
  const sectionId = getParameterByName("sectionId");
  const courseId = getParameterByName("courseId") || "speech";
  const [section, setSection] = useState(null);
  let navigate = useNavigate();
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
  const originalCourseFields = [
    { id: "sectionName", type: "text", placeholder: "STAGE X", label: "Section name" },
    { id: "order", type: "text", placeholder: "1, 2, 3...", label: "Order (in the list)" },
    { id: "title", type: "text", placeholder: "Section Title", label: "Title" },
    {
      id: "description",
      type: "textarea",
      placeholder: "Introduce this section of the course",
      label: "Description",
    },
    {
      id: "preview",
      type: "textarea",
      placeholder: "Summary to show before the user has enrolled",
      label: "Preview",
    },
    {
      id: "theme",
      type: "text",
      placeholder: "Animals",
      label: "Theme for Activities",
    },
    { id: "iconURL", type: "text", placeholder: "https://...", label: "Icon URL" },
    { id: "iconThumbnailURL", type: "text", placeholder: "https://...", label: "Thumbnail URL" },
    { id: "videoURL", type: "text", placeholder: "https://...", label: "Video URL" },
    {
      id: "active",
      type: "text",
      placeholder: "true or false",
      label: "Active",
      defaultValue: "true",
    },
    {
      id: "draft",
      type: "text",
      placeholder: "true or false",
      label: "Draft",
      defaultValue: "false",
    },
    {
      id: "premium",
      type: "text",
      placeholder: "true or false",
      label: "Premium",
      defaultValue: "true",
    },
    { id: "duration", type: "text", placeholder: "5 minutes", label: "Duration" },
    {
      id: "relatedActivities",
      type: "text",
      placeholder: `["123", "246", ...]`,
      label: "Activities (List of ID's)",
    },
  ];
  const [courseFields, setCourseFields] = useState(originalCourseFields);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  // Get the speech strategy input fields and convert back into an object for storing
  function convertSpeechStrategyFields(data) {
    try {
      if (typeof data === "object") {
        let speechStrategy = [];
        const TITLE_PREFIX = "speechstrategy-title";
        const SUBTITLE_PREFIX = "speechstrategy-subtitle";
        const CONTENT_PREFIX = "speechstrategy-content";
        const VIDEO_PREFIX = "speechstrategy-video";
        const DOWNLOAD_PREFIX = "speechstrategy-download";

        Object.keys(data).map((fieldKey) => {
          // WARN: Do not change field naming!
          // loop over titles ONLY, ignore the content field else would duplicate
          // MIN: 3 characters in title, else will skip
          if (
            fieldKey &&
            fieldKey.includes(TITLE_PREFIX) &&
            data[fieldKey] &&
            data[fieldKey].length >= 3
          ) {
            const strategyIndex = fieldKey.split(`${TITLE_PREFIX}-`)[1];
            console.log(`Index is ${strategyIndex}`);
            speechStrategy.push({
              title: data[`${TITLE_PREFIX}-${strategyIndex}`],
              subtitle: data[`${SUBTITLE_PREFIX}-${strategyIndex}`],
              content: data[`${CONTENT_PREFIX}-${strategyIndex}`],
              videoURL: data[`${VIDEO_PREFIX}-${strategyIndex}`],
              downloadURL: data[`${DOWNLOAD_PREFIX}-${strategyIndex}`],
            });

            // Ignore raw fields now processed
            delete data[`${TITLE_PREFIX}-${strategyIndex}`];
            delete data[`${SUBTITLE_PREFIX}-${strategyIndex}`];
            delete data[`${CONTENT_PREFIX}-${strategyIndex}`];
            delete data[`${VIDEO_PREFIX}-${strategyIndex}`];
            delete data[`${DOWNLOAD_PREFIX}-${strategyIndex}`];
          }
        });

        data.speechStrategy = speechStrategy;
        return data;
      }
    } catch (err) {
      console.error(`ERR: Could not parse speech strategu fields #83457890284`);
    }
  }

  async function onSubmit(data) {
    try {
      console.log(`----- Fetching section: ${sectionId}`);
      const processedData = convertSpeechStrategyFields(data);
      processedData.sectionId = sectionId;

      if (
        data.relatedActivities &&
        data.relatedActivities !== "" &&
        data.relatedActivities.length > 0
      ) {
        try {
          processedData.relatedActivities = JSON.parse(data.relatedActivities);
        } catch (err) {
          console.error(`ERR: Could not parse related activities. ${err}`);
          alert(`Related activities value is invalid`);
          return false;
        }
      }

      // Ensure ID is passed across so can update same document
      processedData.id = id;
      processedData.courseId = courseId;

      // Updating course section
      const sectionResponse = await axios.put(
        `${serverUrl}/course/editor/section/${sectionId}`,
        processedData
      );

      if (sectionResponse && sectionResponse.data && sectionResponse.data.success) {
        console.log(`----- Updated section`);
        navigate(-1);
      } else {
        alert(`Could not save.`);
      }
    } catch (err) {
      console.error(`ERR: Could update course section! ${err}`);
    }
  }

  // on-mount
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    reloadFormData();
  }, [section]);

  function reloadFormData() {
    if (typeof section === "undefined" || section === null) {
      return false;
    }

    const newCourseFields = [...originalCourseFields];

    if (section.speechStrategy && section.speechStrategy.length > 0) {
      console.log(`----- Processing ${section.speechStrategy.length} strategies`);

      // DANGER: DO NOT EDIT speechstrategy- naming format, this is parsed on backend when saving
      section.speechStrategy.map((strategy, strategyIndex) => {
        newCourseFields.push({
          id: `speechstrategy-title-${strategyIndex}`,
          type: "text",
          placeholder: "",
          label: `Strategy Title #${strategyIndex}`,
          defaultValue: strategy.defaultValue,
        });

        newCourseFields.push({
          id: `speechstrategy-subtitle-${strategyIndex}`,
          type: "text",
          placeholder: "",
          label: `Strategy Subtitle #${strategyIndex}`,
          defaultValue: strategy.defaultValue,
        });

        newCourseFields.push({
          id: `speechstrategy-content-${strategyIndex}`,
          type: "textarea",
          placeholder: "",
          label: `Strategy Content #${strategyIndex}`,
          defaultValue: strategy.defaultValue,
        });

        newCourseFields.push({
          id: `speechstrategy-video-${strategyIndex}`,
          type: "text",
          placeholder: "",
          label: `Strategy Video #${strategyIndex}`,
          defaultValue: strategy.defaultValue,
        });

        newCourseFields.push({
          id: `speechstrategy-download-${strategyIndex}`,
          type: "text",
          placeholder: "https://...",
          label: `Strategy Download #${strategyIndex}`,
          defaultValue: strategy.defaultValue,
        });
      });
    }

    if (section.links && section.links.length > 0) {
      section.links.map((link, linkIndex) => {
        newCourseFields.push({
          id: `link-name-${linkIndex}`,
          type: "text",
          placeholder: "",
          label: `Link Title #${linkIndex}`,
          value: link.name,
        });

        newCourseFields.push({
          id: `link-url-${linkIndex}`,
          type: "text",
          placeholder: "",
          label: `Link URL #${linkIndex}`,
          value: link.URL,
        });
      });
    }

    setCourseFields(newCourseFields);

    const formSectionId = getValues("sectionId");
    const formTitle = getValues("title");
    console.log(formSectionId);

    // Only add on first load! Or would override our form field values
    if (formSectionId === "" || formTitle === "") {
      console.log(`--- Section data has not been set so adding now`);
      setValue("sectionName", section.sectionName);
      setValue("order", section.order);
      setValue("title", section.title);
      setValue("description", section.description);
      setValue("preview", section.preview);
      setValue("theme", section.theme);
      setValue("iconURL", section.iconURL);
      setValue("iconThumbnailURL", section.iconThumbnailURL);
      setValue("videoURL", section.videoURL);
      setValue("active", section.active);
      setValue("draft", section.draft);
      setValue("premium", section.premium);
      setValue("duration", section.duration);

      // PARSING
      let relatedActivities = section.relatedActivities || [];
      relatedActivities = JSON.stringify(relatedActivities);
      setValue("relatedActivities", relatedActivities);

      if (section.speechStrategy && section.speechStrategy.length > 0) {
        section.speechStrategy.map((strategy, strategyIndex) => {
          setValue(`speechstrategy-title-${strategyIndex}`, strategy.title);
          setValue(`speechstrategy-subtitle-${strategyIndex}`, strategy.subtitle);
          setValue(`speechstrategy-content-${strategyIndex}`, strategy.content);
          setValue(`speechstrategy-video-${strategyIndex}`, strategy.videoURL);
          setValue(`speechstrategy-download-${strategyIndex}`, strategy.downloadURL);
        });
      }

      if (section.links && section.links.length > 0) {
        section.links.map((link, linkIndex) => {
          setValue(`link-name-${linkIndex}`, link.name);
          setValue(`link-url-${linkIndex}`, link.URL);
        });
      }
    }
  }

  function removeSectionProtector() {
    setShowRemovalConfirmation(true);
  }

  async function removeSection() {
    try {
      console.log(`----- Removing section: ${sectionId}`);

      // Remove course data
      const removeSectionResmose = await axios.delete(
        `${serverUrl}/course/editor/removeSection/${sectionId}`
      );

      if (removeSectionResmose && removeSectionResmose.data && removeSectionResmose.data.success) {
        navigate(-1);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch course section!`);
    }
  }

  async function exportCourse() {
    try {
      console.log(`----- Fetching section: ${sectionId}`);

      // Export course data
      const sectionResponse = await axios.get(`${serverUrl}/course/editor/export`);

      if (sectionResponse && sectionResponse.data && sectionResponse.data.export) {
        alert(`Course data copied to your clipboard.`);
        navigator.clipboard.writeText(JSON.stringify(sectionResponse.data.export));
      }
    } catch (err) {
      console.error(`ERR: Could not fetch course section!`);
    }
  }

  function addStrategy() {
    const newSection = { ...section };

    let speechStrategy = section.speechStrategy || [];

    speechStrategy.push({
      title: "",
      subtitle: "",
      content: "",
    });

    newSection.speechStrategy = speechStrategy;
    setSection(newSection);
  }

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching section: ${sectionId}`);

      // Loading course section
      const sectionResponse = await axios.get(`${serverUrl}/course/section/${sectionId}?_id=${id}`);

      if (sectionResponse && sectionResponse.data && sectionResponse.data.section) {
        let section = sectionResponse.data.section;
        console.log(`----- Fetched and set section state`);

        setSection(section);

        // Allow form to render, else form fields will not exist!
        setTimeout(() => {
          console.log(`----- Updating form`);

          reloadFormData();
        }, 500);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch course section!`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row className={"mb-5"}>
        <Col lg={8} xl={6}>
          <h1 className={"mb-3"}>Section Editor</h1>

          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {courseFields && courseFields.length > 0
              ? courseFields.map((field) => {
                  return (
                    <div key={field.id} className={"mb-3"}>
                      <label htmlFor={field.id}>{field.label}</label>

                      {field.type === "text" ? (
                        <input
                          placeholder={field.placeholder}
                          {...register(field.id)}
                          className="form-control"
                          defaultValue={field.defaultValue}
                        />
                      ) : null}

                      {field.type === "textarea" ? (
                        <textarea
                          placeholder={field.placeholder}
                          {...register(field.id)}
                          defaultValue={field.defaultValue}
                          className="form-control"
                          rows="10"
                        />
                      ) : null}
                    </div>
                  );
                })
              : null}

            <div className="mt-2">
              <input className="form-control" type="submit" value="SAVE" />
            </div>
            <div className="mt-4 mb-5">
              <h3>Other options</h3>
              <Button onClick={addStrategy} variant="outline-primary" style={{ marginRight: 10 }}>
                ADD STRATEGY
              </Button>
              <Button
                onClick={exportCourse}
                variant="outline-secondary"
                style={{ marginRight: 10 }}
              >
                EXPORT
              </Button>
              <Button onClick={removeSectionProtector} variant="outline-secondary">
                REMOVE
              </Button>
            </div>
          </form>
        </Col>
      </Row>

      <Modal
        show={showRemovalConfirmation}
        onHide={() => {
          setShowRemovalConfirmation(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will delete the section... are you really sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowRemovalConfirmation(false);
            }}
          >
            Close
          </Button>
          <Button variant="danger" onClick={removeSection}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </EditorWrapper>
  );
}

export default SectionEditor;
