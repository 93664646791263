import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "./utility";
import { useNavigate } from "react-router-dom";
import EditorWrapper from "../../components/editor/wrapper";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function EventEditor() {
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  const courseId = getParameterByName("courseId") || "communikate";
  let navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const originalEventFields = [
    { id: "title", type: "text", placeholder: "LIVE Q&A", label: "Event title" },
    {
      id: "active",
      type: "text",
      placeholder: "true or false",
      label: "Active ['true' or 'false']",
    },
    { id: "date", type: "datetime", placeholder: "SPECIFIC format", label: "Date" },
    { id: "link", type: "text", placeholder: "https://zoom...", label: "Zoom Link" },
  ];
  const [eventFields, setEventFields] = useState(originalEventFields);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  async function onSubmit(data) {
    try {
      if (data.active !== "true" && data.active !== "false") {
        alert(`Active state invalid, must use 'true' or 'false' only.`);
        return false;
      }

      // Updating course section
      const processedData = { ...data };
      processedData.active = data.active === "true";
      processedData.date = new Date(data.date);
      processedData.courseId = courseId;

      // Submit
      const eventResponse = await axios.put(`${serverUrl}/event`, processedData);

      // Response
      if (eventResponse && eventResponse.data && eventResponse.data.success) {
        console.log(`----- Updated event`);
        navigate(-1);
      } else {
        alert(`Could not save.`);
      }
    } catch (err) {
      console.error(`ERR: Could update course section! ${err}`);
    }
  }

  // on-mount
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    reloadFormData();
  }, [event]);

  function reloadFormData() {
    if (typeof event === "undefined" || event === null) {
      return false;
    }

    const formTitle = getValues("title");

    // Only add on first load! Or would override our form field values
    if (formTitle === "") {
      console.log(`--- Section data has not been set so adding now`);
      setValue("title", event.title);
      setValue("link", event.link);
      if (event.date) {
        setValue("date", new Date(event.date));
      }
      setValue("active", event.active?.toString());
    }
  }

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching event`);

      // Loading course section
      const eventResponse = await axios.get(`${serverUrl}/event`);

      if (eventResponse && eventResponse.data && eventResponse.data.event) {
        let event = eventResponse.data.event;
        console.log(`----- Fetched event state`);

        setEvent(event);

        // Allow form to render, else form fields will not exist!
        setTimeout(() => {
          console.log(`----- Updating form`);

          reloadFormData();
        }, 500);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch course section!`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row className={"mb-5"}>
        <Col lg={8} xl={6}>
          <h1 className={"mb-3"}>Event Editor</h1>

          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {eventFields && eventFields.length > 0
              ? eventFields.map((field) => {
                  return (
                    <div key={field.id} className={"mb-3"}>
                      <label htmlFor={field.id}>{field.label}</label>

                      {field.type === "text" ? (
                        <input
                          placeholder={field.placeholder}
                          {...register(field.id)}
                          className="form-control"
                          defaultValue={field.defaultValue}
                        />
                      ) : null}

                      {field.type === "textarea" ? (
                        <textarea
                          placeholder={field.placeholder}
                          {...register(field.id)}
                          defaultValue={field.defaultValue}
                          className="form-control"
                          rows="10"
                        />
                      ) : null}

                      {field.type === "datetime" ? (
                        <input
                          type="datetime-local"
                          placeholder={field.placeholder}
                          className="form-control"
                          {...register(field.id)}
                        />
                      ) : null}
                    </div>
                  );
                })
              : null}

            <div className="mt-2">
              <input className="form-control" type="submit" value="SAVE" />
            </div>
          </form>
        </Col>
      </Row>
    </EditorWrapper>
  );
}

export default EventEditor;
