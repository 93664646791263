import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { format } from "date-fns";
import EditorWrapper from "../../components/editor/wrapper";
import { serverUrl } from "./utility";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function Logs() {
  const [data, setData] = useState(null);
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);

  // on-mount
  useEffect(() => {
    console.log(`----- Mount`);
    fetchData();
  }, []);

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching admin data`);

      const infoResponse = await axios.get(`${serverUrl}/admin/logs`);

      if (infoResponse && infoResponse.data && infoResponse.data.logs) {
        let adminInfo = infoResponse.data;
        console.log(`----- Fetched admin data. ${JSON.stringify(adminInfo)}`);
        setData(adminInfo);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch admin data! ${err}`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row>
        <Col lg={6}>
          <h1 className={"mb-3"}>Device Errors</h1>
        </Col>
      </Row>

      <Row>
        {data && data.logs && data.logs.length > 0
          ? data.logs.map((logItem) => {
              if (logItem && logItem._id) {
                return (
                  <Row
                    style={{ padding: "10px 0", borderBottom: "1px solid lightgrey" }}
                    key={logItem._id}
                  >
                    <Col md={4} lg={2}>
                      <p style={{ marginTop: 0, marginBottom: 5, fontSize: 12 }}>
                        {format(new Date(logItem.created), "HH:mm:ss dd/MM")}
                        <br />
                        {logItem.userId}
                        <br />
                        {logItem.log?.platform} {logItem.log?.versionCode}
                      </p>
                    </Col>
                    <Col md={8} lg={7}>
                      <p style={{ marginTop: 0, marginBottom: 5, fontSize: 12 }}>
                        {typeof logItem.log?.message === "object"
                          ? JSON.stringify(logItem.log?.message)
                          : logItem.log?.message}
                      </p>
                    </Col>
                    <Col lg={3}>
                      <p style={{ marginTop: 0, marginBottom: 5, fontSize: 12 }}>
                        {JSON.stringify(logItem.log?.meta)}
                      </p>
                    </Col>
                  </Row>
                );
              }
            })
          : null}
      </Row>
    </EditorWrapper>
  );
}

export default Logs;
