import { createSlice } from "@reduxjs/toolkit";

// https://react-redux.js.org/tutorials/quick-start
export const partnerStore = createSlice({
  name: "partner",
  initialState: {
    partner: null,
    referrals: null,
  },
  reducers: {
    setPartner: (state, action) => {
      state.partner = action.payload;
    },
    setReferrals: (state, action) => {
      state.referrals = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPartner, setReferrals } = partnerStore.actions;

export default partnerStore.reducer;
