import "../styles/global.scss";
import mockup from "../images/pippinmockup1.png";
import axios from "axios";
import progress from "../images/progress.png";
import chat from "../images/chat.png";
import learn from "../images/learn.png";
import React, { useEffect, useState } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import { useForm } from "react-hook-form";
import pippinLogo from "../images/pippin-purple.png";
import Feature from "../components/feature";
import Footer from "../components/footer";

function PartnerTerms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="PartnerTerms">
        <a href={`/`}>
          <img src={pippinLogo} className={styles.parrot} />
        </a>

        <div className={`container mainMarginTop`} style={{ marginBottom: 60 }}>
          <div className="row">
            <div className="col-sm-1 col-lg-2 col-xl-3"></div>

            <div className="col-sm-10 col-lg-8 col-xl-6">
              <h1 style={{ margin: 0, marginBottom: 15 }}>Partner Terms and Conditions</h1>
              <div className="rte w-richtext">
                <h3>Effective Date: 28th July 2024</h3>

                <p>
                  Company Information: Family Timeline Ltd. (trading as "Pippin") is a company
                  registered in England & Wales under company number 12763607.
                </p>

                <p>
                  By signing up as a partner with Family Timeline Ltd. ("Pippin"), you agree to the
                  following terms and conditions:
                </p>

                <p style={{ marginBottom: 30 }}>
                  <h4 style={{ fontWeight: "bold" }}>1. Referral Program </h4>
                  1.1. Partners must use their unique code when referring individuals to Pippin.
                  1.2. No commission will be paid for referrals made without the use of the unique
                  referral code.{" "}
                </p>

                <p style={{ marginBottom: 30 }}>
                  <h4 style={{ fontWeight: "bold" }}>2. Commission and Payouts </h4>
                  2.1. Partners will earn a commission of 40% on qualifying revenue generated from
                  subscriptions referred by the partner.
                  <br />
                  2.2. Qualifying revenue includes all in-app subscription purchases, including
                  those made using discounted offers.
                  <br />
                  2.3. Commission amounts are calculated before any applicable taxes.
                  <br />
                  2.4. Payouts to partners will be made once per quarter, within 30 days of the
                  quarter's end.
                  <br />
                  2.5. The minimum payout threshold is £20. If the commission earned in a quarter is
                  less than £20, the amount will roll over to the next quarter.{" "}
                </p>

                <p style={{ marginBottom: 30 }}>
                  <h4 style={{ fontWeight: "bold" }}>3. Dispute Resolution </h4>
                  3.1. In the event of a dispute regarding commission payments or any other matter,
                  the partner must raise the query in writing with Pippin within 30 days of the
                  issue arising.{" "}
                </p>

                <p style={{ marginBottom: 30 }}>
                  <h4 style={{ fontWeight: "bold" }}>4. Limitation of Liability </h4>
                  4.1. To the maximum extent permitted by applicable law, Pippin will not be liable
                  for any indirect, incidental, special, consequential, or punitive damages, or any
                  loss of profits or revenues, whether incurred directly or indirectly, or any loss
                  of data, use, goodwill, or other intangible losses, resulting from (i) your use or
                  inability to use the referral program; (ii) any unauthorized access to or use of
                  our servers and/or any personal information stored therein.
                  <br />
                  4.2. Pippin’s total liability to you for any claims under these terms, including
                  for any implied warranties, is limited to the amount you have earned as commission
                  in the 12 months preceding the event giving rise to the liability.{" "}
                </p>

                <p style={{ marginBottom: 30 }}>
                  <h4 style={{ fontWeight: "bold" }}>5. Data Protection </h4>
                  5.1. Both parties agree to comply with all applicable data protection laws and
                  regulations.
                  <br />
                  5.2. Partners are responsible for ensuring that they have the necessary consents
                  and permissions to share any personal data with Pippin.
                  <br />
                  5.3. Pippin will process any personal data received from partners in accordance
                  with its Privacy Policy.{" "}
                </p>

                <p style={{ marginBottom: 30 }}>
                  <h4 style={{ fontWeight: "bold" }}>6. Termination and Amendments </h4>
                  6.1. Either party may terminate this agreement by giving 30 days' notice to the
                  other party.
                  <br />
                  6.2. Pippin reserves the right to change these terms and conditions by giving 30
                  days' notice.{" "}
                </p>

                <p style={{ marginBottom: 30 }}>
                  Continued participation in the referral program after such changes will constitute
                  acceptance of the new terms and conditions. By signing up as a partner, you
                  acknowledge that you have read, understood, and agree to be bound by these terms
                  and conditions.
                  <br /> <br />
                  Family Timeline Ltd. (trading as "Pippin") Registered in England & Wales, Company
                  Number 12763607
                </p>
              </div>
            </div>

            <div className="col-sm-1 col-lg-2 col-xl-3"></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default PartnerTerms;
