import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { format } from "date-fns";
import { getParameterByName, serverUrl } from "./utility";
import EditorWrapper from "../../components/editor/wrapper";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function AdminOverview() {
  const [data, setData] = useState(null);
  const courseId = getParameterByName("courseId") || "communikate";
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  const premiumOnly = getParameterByName("premiumOnly") === "true";
  const location = useLocation();

  // on-mount
  useEffect(() => {
    console.log(`----- Mount or location change`);
    fetchData();
  }, [location]);

  function getUserColor(user) {
    if (!user.premium) {
      return "black";
    }

    if (user.premium && user.courseId === "communikate") {
      return "#e0ae09";
    }

    if (user.premium && user.courseId === "pippin") {
      return "blue";
    }
  }

  const getFormattedDOB = (dob) => {
    try {
      if (typeof dob === "string") {
        return format(new Date(dob), "MMM yyyy");
      }

      return "";
    } catch (error) {
      console.error(`Formatted DOB error: ${error}`);
    }
  };

  // Creates new virtual fields -> { activitiesViewed, activitiesCompleted, courseSectionsCompleted }
  function processActivityAndCourseInfo(activeUsers) {
    if (activeUsers && activeUsers.length > 0) {
      activeUsers.forEach((user) => {
        // Activity
        // Set activity defaults
        user.activitiesViewed = 0;
        user.activitiesCompleted = 0;

        if (user && user.organisationData && user.organisationData.activityStatus) {
          user.organisationData.activityStatus.forEach((activity) => {
            user.activitiesViewed++;

            if (activity.completed) {
              user.activitiesCompleted++;
            }
          });
        }

        // Course
        // Set course defaults
        user.courseSectionsCompleted = 0;
        user.courseStrategiesCompleted = 0;

        if (user && user.organisationData && user.organisationData.courseStatus) {
          user.organisationData.courseStatus.forEach((course) => {
            if (course.completed) {
              user.courseSectionsCompleted++;
            }

            if (course.strategyProgress?.length > 0) {
              course.strategyProgress.forEach((strategy) => {
                if (strategy?.completed) {
                  user.courseStrategiesCompleted++;
                }
              });
            }
          });
        }
      });
    }

    return activeUsers;
  }

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching admin data`);

      const infoResponse = await axios.get(
        `${serverUrl}/admin/info?courseId=${courseId}&premiumOnly=${premiumOnly}`
      );

      if (infoResponse && infoResponse.data && infoResponse.data.activeUsers) {
        let adminInfo = infoResponse.data;
        console.log(`----- Fetched admin data`);

        if (adminInfo.activeUsers) {
          adminInfo.activeUsers = processActivityAndCourseInfo(adminInfo.activeUsers);
        }

        setData(adminInfo);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch admin data! ${err}`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row>
        <Col lg={6}>
          <h1 className={"mb-3"}>Admin Overview</h1>
        </Col>
      </Row>

      {data && typeof data.totalUsers === "number" ? (
        <Row>
          <Col lg={12} style={{ marginBottom: 10 }}>
            <span className={"mb-3"}>Total Users: {data.totalUsers} | </span>
            <span className={"mb-3"}>Premium Users: {data.totalPremiumUsers}</span>
          </Col>
        </Row>
      ) : null}

      <Row>
        {data && data.activeUsers && data.activeUsers.length > 0
          ? data.activeUsers.map((user) => {
              // Check URL exists but exclude PDF's
              if (user && user._id) {
                return (
                  <Col xs={12} key={user._id} style={{ marginBottom: 15 }}>
                    <span style={{ fontWeight: "bold", color: getUserColor(user) }}>
                      {user.email || user._id}
                    </span>
                    <p style={{ marginTop: 0, marginBottom: 5, fontSize: 12 }}>
                      {user.premium ? "Premium" : "Basic"} ({user.courseId}) | {user.signUpPlatform}{" "}
                      {user.countryCode} | Login: {user.authType}
                    </p>
                    {user.organisationData ? (
                      <p style={{ marginTop: 10, marginBottom: 10, fontSize: 12 }}>
                        <span style={{ fontWeight: "bold" }}>
                          {user.organisationData?.name} ({user.organisationData?.communicationLevel}
                          )
                        </span>
                        <br />
                        <span style={{}}>DOB: {getFormattedDOB(user.organisationData?.dob)}</span>
                        <br />
                        {typeof user.wordTrackingCount === "number" ? (
                          <>
                            {`Words: ${user.wordTrackingCount.toString()}. `}
                            <br />
                          </>
                        ) : null}
                        Course:{" "}
                        {user.courseSectionsCompleted > 0
                          ? `${user.courseSectionsCompleted} sections. ${user.courseStrategiesCompleted} strategies`
                          : "N/A"}
                        .
                        <br />
                        Activities: {user.activitiesViewed} viewed, {user.activitiesCompleted}{" "}
                        completed.
                      </p>
                    ) : null}
                  </Col>
                );
              }
            })
          : null}
      </Row>
    </EditorWrapper>
  );
}

export default AdminOverview;
