import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { serverUrl } from "./utility";
import EditorWrapper from "../../components/editor/wrapper";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function AdminPartners() {
  const [data, setData] = useState(null);
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);

  // on-mount
  useEffect(() => {
    console.log(`----- Mount`);
    fetchData();
  }, []);

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching admin data`);

      const infoResponse = await axios.get(`${serverUrl}/admin/partners`);

      if (infoResponse && infoResponse.data && infoResponse.data.partners) {
        console.log(`----- Fetched partners data`);
        setData(infoResponse.data);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch admin data! ${err}`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row>
        <Col lg={6}>
          <h1 className={"mb-3"}>Partners</h1>
        </Col>
      </Row>

      {data ? (
        <Row>
          <Col lg={12} style={{ marginBottom: 10 }}>
            <span className={"mb-3"}>Total Partners: {data.partners?.length} </span>
          </Col>
        </Row>
      ) : null}

      {data?.partners && data.partners.length > 0 ? (
        <Row>
          <Col lg={12} style={{ marginBottom: 10 }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Partner</th>
                  <th>Email</th>
                  <th>Link</th>
                  <th>Licensing</th>
                  <th>Data Sharing</th>
                  <th>Referrals</th>
                </tr>
              </thead>
              <tbody>
                {data.partners.map((partner) => {
                  return (
                    <tr key={partner?._id}>
                      <td>{partner.name || partner?._id}</td>
                      <td>
                        <a href={`mailto:${partner?.email}`}>{partner?.email}</a>
                      </td>
                      <td>{partner.referralLink}</td>
                      <td>
                        {partner.licenseLimit > 0
                          ? `${partner.licensesUsed} / ${partner.licenseLimit}`
                          : ""}
                      </td>
                      <td>{partner?.requestDataSharing ? "Yes" : "No"}</td>
                      <td>{partner.referralCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : null}
    </EditorWrapper>
  );
}

export default AdminPartners;
