import "../styles/global.scss";
import mockup from "../images/parentLearning.png";
import axios from "axios";
import earlyYears from "../images/early-years.jpg";
import teacher from "../images/teacher.png";
import Feature from "../components/feature";
import Footer from "../components/footer";
import React, { useEffect } from "react";
import styles from "./landing.module.scss";
import success from "../images/success.png";
import variables from "../styles/utils.module.scss";
import pippinLogo from "../images/pippin-logo-parrot.png";

function triggerDownloadInterest() {
  if (document.referrer) {
    axios.post("https://pippinspeech.com/user/website/downloadInterest", {
      miwn: "82hjd",
      referrer: document.referrer,
    });
  }
}

function Education() {
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Landing">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
            <img src={mockup} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0 }}>
            Partner with Pippin - Early Years Professionals
          </h1>

          <h2 className={`${styles.subtitle} h5`} style={{ marginTop: 15 }}>
            Supporting Children in the Early Years
          </h2>
        </div>
      </div>

      <Feature imagePath={teacher} reverse={true} imageLarge={true}>
        <h3>Will Pippin Help?</h3>
        <p>
          Absolutely, yes! Discover how our online course can transform your approach to early
          childhood education. Our expertly designed strategies lay the foundation for High Quality
          Interactions, crucial for developing a child's communication and language skills, a prime
          area of the Early Years Foundation Stage (EYFS).
        </p>

        <p>
          Access the course conveniently through our website, or download our app to get the same
          comprehensive content that parents and caregivers use.
        </p>
      </Feature>

      <Feature
        imagePath={`https://pippin-public.s3.eu-west-2.amazonaws.com/wedding-gift.png`}
        reverse={false}
      >
        <h3>Partnership Opportunities</h3>
        <p>
          At Pippin, we passionately believe in parents and caregivers being able to access high
          quality, up to date information and support in a timely manner - that's how the app came
          about after all!
        </p>

        <p>
          If as a Healthcare or Education Provider you would like to recommend Pippin to the
          families you work with, Pippin offers a 40% commission when someone signs up to Pippin
          Premium using your referral link.
        </p>
      </Feature>

      <Feature
        imagePath={`https://pippin-public.s3.eu-west-2.amazonaws.com/puzzle.png`}
        reverse={true}
      >
        <h3>Impact on Education</h3>

        <p>
          Speech & Language UK highlighted in their recent report{" "}
          <a
            href="https://speechandlanguage.org.uk/the-issue/our-campaigns/listening-to-unheard-children/"
            target="blank"
          >
            "Listening to Unheard Children"
          </a>{" "}
          (2023), that the number of children with speech, language and communication needs (SLCN)
          has increased to 1.9 million children in the UK - which equates to 1 in 5 children having
          difficulty with their Speech, Language and Communication skills.
        </p>
        <p>
          Of the 1,000 teachers that took part in their survey, Speech & Language UK found that 78%
          of teachers felt that parents and caregivers did not know the importance of supporting
          their child at home with their language and communication skills.
        </p>
        <p>
          Our hope is that Pippin can help ease this load for schools by providing a cost effective,
          research based support for all parents in the Early Years so that schools and families can
          provide support in a joined up approach.
        </p>
        <p>
          Please contact us to discuss either a commission based referral approach, or securing a
          licence to share with all your families.
        </p>
      </Feature>

      <Feature imagePath={success} reverse={false}>
        <h3>Sign Up</h3>
        <p>
          Register online as a partner and get a referral link instantly. <br />
          We will then arrange a convenient time to discuss any questions you have.
        </p>
        <div style={{ width: 200 }}>
          <a
            id="partner-sign-up-link"
            href="/#/partner-sign-up"
            target="_blank"
            className={"no-underline"}
          >
            <div
              className="button"
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <span>Partner Sign Up</span>
            </div>
          </a>
        </div>
      </Feature>

      <Footer />
    </div>
  );
}

export default Education;
