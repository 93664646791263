import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { setStaffUser, setUser } from "../../store/user";
import axios from "axios";
import { serverUrl } from "../editor/utility";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.scss";
import loginImg from "../../images/loginImg.png";
import pippinParrot from "../../images/pippin-logo-parrot.png";
import pippinLogoImg from "../../images/pippin-purple.png";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function Login() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();

  // WARN: When editing use the ngrok domain as localhost will be blocked!
  async function onLogin(data) {
    try {
      console.log(`Login...`);

      axios
        .get(
          `${serverUrl}/login?username=${encodeURIComponent(
            data.username
          )}&password=${encodeURIComponent(data.password)}&staffCheck=true`
        )
        .then(async (response) => {
          // Persist to redux store, redirection then handled by componentDidUpdate (redux store update!)
          if (response && response.data && response.data.success) {
            const { user, token, isStaffUser } = response.data.data;
            console.log(`--- User is: ${user?._id}`);

            localStorage.setItem("token", token);
            dispatch(setUser(user));
            dispatch(setStaffUser(isStaffUser));

            if (user.partnerId) {
              navigate("/partner/home");
            } else {
              navigate("/course/home");
            }
          } else {
            console.info(`ERR: Could not login. ${JSON.stringify(response.data)}`);
          }
        })
        .catch((error) => {
          console.error(`Unable to login, server error. ${JSON.stringify(error)}`);
        });
    } catch (err) {
      console.error(`ERR: User login error. ${err}`);
    }
  }

  return (
    <>
      <Container fluid className={`no-padding no-margin container-fluid`}>
        <Row>
          <Col lg={6} xl={7} className={styles.loginMarketing}>
            <img src={loginImg} className={styles.loginImg} />
            <h2 className={styles.marketingText}>Help your child find their voice.</h2>
          </Col>

          <Col lg={6} xl={5} className={`mt-5 ${styles.loginArea}`}>
            <img src={pippinLogoImg} className={styles.pippinLogoImg} />

            {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
            <form onSubmit={handleSubmit(onLogin)} className={styles.loginForm}>
              <div className={"mb-3"}>
                <label htmlFor={"username"}>Email address</label>
                <input {...register("username")} className="form-control" />
              </div>

              <div className={"mb-3"}>
                <label htmlFor={"password"}>Password</label>
                <input {...register("password")} type="password" className="form-control" />
              </div>

              <div style={{ marginTop: "20px" }}>
                <input className="form-control" type="submit" value={`Login`} />
              </div>
            </form>

            <div className={styles.loginBottom}>
              Not registered yet? <a href={`https://pippinspeech.com/`}>Download</a> our mobile app
              to get started.
              <br />
              Speech Professional? Sign up as a{" "}
              <a href={`https://pippinspeech.com/speech-and-language-therapists`}>Partner</a>.
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
