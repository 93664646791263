import React from "react";
import styles from "./header.module.scss";
import pippinLogo from "../images/pippin-logo-parrot.png";

// A "Hero" Row
export default function HeaderMarketing({}) {
  return (
    <header className={styles.header}>
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>
    </header>
  );
}
