import "../../styles/global.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialIcon, { colorPalette } from "material-icons-react";
import Table from "react-bootstrap/Table";
import { setUser } from "../../store/user";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "../utility";
import { useNavigate } from "react-router-dom";
import PartnerWrapper from "../../components/partner/wrapper";
import styles from "./partnerHome.module.scss";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function PartnerReferral() {
  const user = useSelector((state) => state.userStore.user);
  const partner = useSelector((state) => state.partnerStore.partner);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.partnerId) {
      console.error(`User is NOT a partner`);
      navigate("/");
    }
  }, [user]);

  return (
    <PartnerWrapper>
      <Row className={""}>
        <Col lg={12}>
          <div className={styles.mainAreaPadded}>
            <div>
              <h2>Links</h2>
              <p>
                Earn a referral fee for anyone that signs up using your unique referral link and
                goes on to upgrade to a paid subscription.
              </p>
            </div>

            <div>
              <div style={{ fontWeight: "bold" }}>Referral Link</div>
              <a href={partner?.referralLink}>{partner?.referralLink}</a>
            </div>

            <div style={{ marginTop: 20 }}>
              <div style={{ fontWeight: "bold" }}>Referral QR Code</div>
              <img
                src={partner?.referralLinkQR}
                style={{ width: 150, height: 150, marginTop: 3 }}
                title="PippinQRCode"
                alt="Pippin QR Code"
              />
            </div>

            {partner &&
            partner?.referralLicensingLink &&
            partner.licenseLimit &&
            partner.licenseLimit > partner?.licensesUsed ? (
              <div style={{ marginTop: 20 }}>
                <div style={{ fontWeight: "bold" }}> Licensing</div>
                You currently have {partner.licenseLimit - partner?.licensesUsed} licenses
                remaining. <br />
                You must share this specific link to give your clients Premium for free:
                <br />
                <a href={partner?.referralLicensingLink}>{partner?.referralLicensingLink}</a>
              </div>
            ) : null}

            {partner && !partner.licenseLimit ? (
              <div style={{ marginTop: 20 }}>
                <div style={{ fontWeight: "bold" }}>Licensing</div>
                <span>
                  You do not currently have any licenses. <br />
                  Licensing allows your clients to automatically receive Pippin Premium. <br />
                  Contact us if you wish to purchase 10 or more discounted licences.
                </span>
              </div>
            ) : null}

            {partner && partner.licenseLimit && partner.licenseLimit <= partner?.licensesUsed ? (
              <div style={{ marginTop: 20 }}>
                <div style={{ fontWeight: "bold" }}>Licensing</div>
                <span>
                  All of your licenses have now been issued ({partner.licenseLimit}).
                  <br />
                  Please contact us to purchase additional licenses.
                </span>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </PartnerWrapper>
  );
}

export default PartnerReferral;
