import "../styles/global.scss";
import mockup from "../images/parentLearning.png";
import axios from "axios";
import speedometer from "../images/speedometer.png";
import pyramid from "../images/pyramid-chart.png";
import gem from "../images/gem.png";
import success from "../images/success.png";
import colorWheel from "../images/color-wheel.png";
import Feature from "../components/feature";
import Footer from "../components/footer";
import React, { useEffect } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import pippinLogo from "../images/pippin-logo-parrot.png";

function triggerDownloadInterest() {
  if (document.referrer) {
    axios.post("https://pippinspeech.com/user/website/downloadInterest", {
      miwn: "82hjd",
      referrer: document.referrer,
    });
  }
}

function Partner() {
  const { innerWidth: width, innerHeight: height } = window;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Landing">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
            <img src={mockup} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0 }}>
            Partner with Pippin - Speech and Language Therapist
          </h1>

          <h2 className={`${styles.subtitle} h5`} style={{ marginTop: 15 }}>
            Why share Pippin with your clients?
          </h2>
        </div>
      </div>

      <Feature imagePath={speedometer} reverse={false}>
        <h3>Capacity Limits</h3>
        <p>
          Pippin is an ideal resource to be able to recommend to families when you're at full
          capacity or for families on your waiting list.
        </p>
      </Feature>

      <Feature imagePath={pyramid} reverse={true}>
        <h3>Digital Triage & Onboarding</h3>
        <p>
          The screening assessment and tracking tools are a useful triaging tool to help you manage
          and plan your assessments effectively as well making them as time efficient as possible;
          no need to start from scratch or have lengthy telephone conversations to gather this
          information ahead of the initial assessment. Families can simply select to share this
          information with you, and you'll be able to view or export the report from your Pippin
          dashboard.
        </p>
      </Feature>

      <Feature imagePath={colorWheel} reverse={false}>
        <h3>Personalised Branding</h3>
        <p>
          Families who joined Pippin via your referral link will access a personalised version of
          the app - this includes your name, logo and contact details; so any families that feel
          need additional support are referred back to you via the app.
        </p>
      </Feature>

      <Feature imagePath={gem} reverse={true}>
        <h3>Additional Income</h3>
        <p>
          Pippin offers a generous 40% commission when someone signs up to Pippin Premium via your
          referral link. You can see a record of all referrals from your dashboard and funds are
          sent to you at the end of each month. Terms and conditions apply.
        </p>
      </Feature>

      <Feature imagePath={success} reverse={false}>
        <h3>Sign Up</h3>
        <p>
          Register online as a partner and get a referral link instantly. <br />
          We will then arrange a convenient time to discuss any questions you have.
        </p>
        <div style={{ width: 200 }}>
          <a
            id="partner-sign-up-link"
            href="/#/partner-sign-up"
            target="_blank"
            className={"no-underline"}
          >
            <div
              className="button"
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <span>Partner Sign Up</span>
            </div>
          </a>
        </div>
      </Feature>

      <Footer />
    </div>
  );
}

export default Partner;
