import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "../utility";
import { useNavigate } from "react-router-dom";
import CourseWrapper from "../../components/course/wrapper";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function CourseSettings() {
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  let navigate = useNavigate();

  return (
    <CourseWrapper isStaffUser={isStaffUser}>
      <Row className={"mb-5"}>
        <Col lg={8} xl={6} style={{ padding: "10px 15px" }}>
          <h1 className={"mb-3"}>Settings</h1>
        </Col>
      </Row>
    </CourseWrapper>
  );
}

export default CourseSettings;
