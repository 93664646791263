// MUST have lint rule or will not build in production (yarn run build)
/* eslint-disable no-useless-escape */
export function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const serverUrl =
  window.location.href.includes("localhost") || window.location.href.includes("ngrok")
    ? "http://localhost"
    : "https://pippinspeech.com";
