import { createSlice } from "@reduxjs/toolkit";

// https://react-redux.js.org/tutorials/quick-start
export const userStore = createSlice({
  name: "user",
  initialState: {
    user: null,
    isStaffUser: null,
    // Set to true when a token is confirmed as invalid
    notLoggedIn: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setStaffUser: (state, action) => {
      state.isStaffUser = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.notLoggedIn = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setStaffUser, logout } = userStore.actions;

export default userStore.reducer;
