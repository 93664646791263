import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { serverUrl } from "./utility";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/editor/navigation";
import EditorWrapper from "../../components/editor/wrapper";
import { getParameterByName } from "./utility";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function CourseEditor() {
  const [course, setCourse] = useState(null);
  let navigate = useNavigate();
  const courseId = getParameterByName("courseId") || "speech";
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);

  // on-mount
  useEffect(() => {
    fetchData();
  }, [window.location.href]);

  const viewActivities = async () => {
    navigate("/admin/activityEditor");
  };

  const createNewSection = async () => {
    try {
      console.log(`--- Creating new section`);

      // Loading course section
      const addCourseResponse = await axios.post(
        `${serverUrl}/course/editor/addSection/${courseId}`
      );

      if (addCourseResponse && addCourseResponse.data && addCourseResponse.data.success) {
        console.log(`----- Added new course section`);

        fetchData();
      }
    } catch (err) {
      console.error(`ERR: Could not fetch course!`);
    }
  };

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching course`);

      // Loading course section
      // TODO: courseId param
      const courseOverview = await axios.get(
        `${serverUrl}/course/overview?includeDrafts=true&courseId=${courseId}`
      );

      if (courseOverview && courseOverview.data && courseOverview.data.sections) {
        let course = courseOverview.data.sections;
        console.log(`----- Fetched and set course state`);

        setCourse(course);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch course!`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row>
        <Col lg={6}>
          <h1 className={"mb-3"}>Course Editor</h1>

          {course && course.length > 0
            ? course.map((section) => {
                return (
                  <div key={section._id} className={"mb-3"}>
                    {section.order}
                    {". "}
                    <a
                      href={`/#/admin/sectionEditor?_id=${section._id}&courseId=${courseId}&sectionId=${section.sectionId}`}
                    >
                      {section.title}
                    </a>
                  </div>
                );
              })
            : null}
        </Col>
      </Row>

      <Row>
        <Col>
          <Button onClick={createNewSection} variant="outline-primary" style={{ marginRight: 10 }}>
            ADD SECTION
          </Button>
        </Col>
      </Row>
    </EditorWrapper>
  );
}

export default CourseEditor;
