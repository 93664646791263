import { Nav, Navbar, Container, Row, Col } from "react-bootstrap";
import React from "react";
import pippinLogo from "../../images/pippin-purple.png";
import { serverUrl } from "../../screens/utility";
import axios from "axios";
import { setUser, setStaffUser } from "../../store/user";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function CourseWrapper({ isStaffUser, children }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  return (
    <Container fluid className={"courseApp"}>
      <Row>
        <Navbar expand="lg" className="navbar-wrapper">
          <Container fluid style={{ alignItems: "flex-start" }}>
            <Navbar.Brand href="#">
              <img src={pippinLogo} height={25} style={{ marginTop: 4 }} />
            </Navbar.Brand>

            <Col md={8} className={"columnRight"}>
              <Navbar.Toggle aria-controls="navbarScroll" />

              <Navbar.Collapse id="pippinNavBar">
                <Nav className="me-auto">
                  <Nav.Link href="#/course/home">Course</Nav.Link>
                  {/*   <Nav.Link href="#/course/settings">Settings</Nav.Link> */}

                  {isStaffUser === true ? (
                    <Nav.Link href="#/admin/courseEditor?courseId=communikate">Admin</Nav.Link>
                  ) : null}

                  <Nav.Link
                    href="#"
                    onClick={async () => {
                      await axios.get(`${serverUrl}/logout`);
                      localStorage.removeItem("token");
                      dispatch(setUser(null));
                      dispatch(setStaffUser(false));

                      navigate("/login");
                    }}
                  >
                    Logout
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Col>

            {/*  <Navigation /> */}
          </Container>
        </Navbar>

        <Container fluid>
          <Col md={12}>{children}</Col>
        </Container>
      </Row>
    </Container>
  );
}
