import "../styles/global.scss";
import mockup from "../images/pippinmockup1.png";
import axios from "axios";
import progress from "../images/progress.png";
import chat from "../images/chat.png";
import learn from "../images/learn.png";
import React, { useState } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import { useForm } from "react-hook-form";
import pippinLogo from "../images/pippin-logo-parrot.png";
import Feature from "../components/feature";

function Deeplink() {
  return (
    <div className="Deeplink">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader} style={{ minHeight: "100vh" }}>
        <div className={styles.left}>
          <div style={{ position: "relative" }}>
            <img src={mockup} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0 }}>
            Retry On Your Phone
          </h1>

          <p style={{ color: "white", marginTop: 15 }}>
            Please re-open this link on your phone or tablet device.
            <br />
            The link isn't available from your computer.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Deeplink;
