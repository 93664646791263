import "../styles/global.scss";
import mockup from "../images/parentLearning.png";
import axios from "axios";
import checklist from "../images/checklist.png";
import Feature from "../components/feature";
import Footer from "../components/footer";
import { useEffect, useState } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import pippinLogo from "../images/pippin-logo-parrot.png";
import "add-to-calendar-button";
import { serverUrl } from "./utility";

function Event() {
  const { innerWidth: width, innerHeight: height } = window;
  const [event, setEvent] = useState(null);

  useEffect(() => {
    async function getEvent() {
      const eventResponse = await axios.get(`${serverUrl}/event`);

      if (eventResponse?.data) {
        console.log(`Event info: ${JSON.stringify(eventResponse?.data)}`);
        const event = eventResponse?.data?.event;

        try {
          event.date = new Date(event.date);
          event.dateString = `${event.date.toLocaleDateString()} at ${event.date.toLocaleTimeString(
            [],
            { hour: "2-digit", minute: "2-digit" }
          )}`;

          // Calendar button API
          event.startDate = event.date.toISOString().split("T")[0];
          event.startTime = event.date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          event.endTime = new Date(event.date.getTime() + 1000 * 60 * 30)?.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
        } catch (error) {
          event.date = "TBC";
        }

        setEvent(eventResponse?.data?.event);
      }
    }

    getEvent();
  }, []);

  return (
    <div className="Landing">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
            <img src={mockup} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0 }}>
            Event: {event?.title}
          </h1>

          <h2 className={`${styles.subtitle} h5`} style={{ marginTop: 5 }}>
            {event?.dateString || "TBC"} | Online Event
          </h2>

          <div className={styles.downloadGap} style={{ marginTop: 5 }}>
            <add-to-calendar-button
              name={`Pippin: ${event?.title}`}
              description={`Join our next online event, hosted by Kate Burgess (Speech and Language Therapist).

<b>Zoom Link</b>
<a href="${event?.link}">${event?.link || "TBC"}</a>`}
              options="'Apple','Google', 'Outlook.com'"
              location="Online"
              startDate={event?.startDate}
              startTime={event?.startTime}
              endTime={event?.endTime}
              timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone || "GMT"}
            ></add-to-calendar-button>
          </div>
        </div>
      </div>

      <Feature imagePath={checklist} reverse={true} imageLarge={false}>
        <h3>Event Information</h3>
        <p>
          Our Live Q&A session is hosted each month by Kate Burgess, our Speech and Language
          Therapist with over 15 years of experience. This event is exclusively for Pippin Premium
          users.
        </p>
        <p>
          This is an opportunity for you to ask Kate your specific questions about your child's
          speech development and get the answers you need.
        </p>
        <p>Save the event details, including Zoom link, to your calendar using the button below.</p>

        <div>
          <add-to-calendar-button
            name={`Pippin: ${event?.title}`}
            description={`Join our next online event, hosted by Kate Burgess (Speech and Language Therapist).

<b>Zoom Link</b>
<a href="${event?.link}">${event?.link || "TBC"}</a>`}
            options="'Apple','Google'"
            location="Online"
            startDate={event?.startDate}
            startTime={event?.startTime}
            endTime={event?.endTime}
            timeZone="GMT"
          ></add-to-calendar-button>
        </div>
      </Feature>

      <Footer />
    </div>
  );
}

export default Event;
