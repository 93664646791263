import { Nav, Navbar, Container, Row, Col } from "react-bootstrap";
import React from "react";
import pippinLogo from "../../images/pippin-purple.png";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setStaffUser, setUser } from "../../store/user";
import { serverUrl } from "../../screens/utility";
import styles from "./editorWrapper.module.scss";

export default function EditorWrapper({ isStaffUser, children }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (isStaffUser !== true) {
    alert(`Not Authorised.
You are not logged into an account with admin permissions.`);

    navigate("/");
    return false;
  }

  return (
    <Container fluid className={"courseApp"}>
      <Row>
        <Navbar expand="lg" className="bg-body-tertiary" style={{ position: "relative" }}>
          <Container fluid style={{ alignItems: "flex-start" }}>
            <Navbar.Brand href="#">
              <img src={pippinLogo} height={25} style={{ marginTop: 4 }} />
            </Navbar.Brand>

            <Col md={8} className={"columnRight"}>
              <Navbar.Toggle aria-controls="navbarScroll" />

              <Navbar.Collapse id="pippinNavBar">
                <Nav className="me-auto">
                  <Nav.Link href={`#/admin/overview?courseId=communikate`}>Users</Nav.Link>
                  <Nav.Link href="#/admin/partners">Partners</Nav.Link>
                  <Nav.Link href={`#/admin/courseEditor?courseId=communikate`}>Sections</Nav.Link>
                  <Nav.Link href={`#/admin/activityEditor`}>Activities</Nav.Link>
                  <Nav.Link href={`#/admin/images`}>Images</Nav.Link>
                  <Nav.Link href={`#/admin/email`}>Email</Nav.Link>
                  <Nav.Link href="#/admin/event">Event</Nav.Link>
                  <Nav.Link href="#/course/home">Course</Nav.Link>

                  <Nav.Link
                    href="#"
                    onClick={async () => {
                      await axios.get(`${serverUrl}/logout`);
                      localStorage.removeItem("token");
                      dispatch(setUser(null));
                      dispatch(setStaffUser(false));

                      navigate("/login");
                    }}
                  >
                    Logout
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Col>
          </Container>
        </Navbar>

        <Container fluid>
          <Col md={12} className={styles.innerWrapper}>
            {children}
          </Col>
        </Container>
      </Row>
    </Container>
  );
}
