import { createSlice } from "@reduxjs/toolkit";

// https://react-redux.js.org/tutorials/quick-start
export const organisationStore = createSlice({
  name: "organisation",
  initialState: {
    organisation: null,
  },
  reducers: {
    setOrganisation: (state, action) => {
      state.organisation = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrganisation } = organisationStore.actions;

export default organisationStore.reducer;
