import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { serverUrl } from "./utility";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/editor/navigation";
import EditorWrapper from "../../components/editor/wrapper";
import { sortBy } from "underscore";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function ActivityEditor() {
  const [activities, setActivities] = useState(null);
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  let navigate = useNavigate();

  // on-mount
  useEffect(() => {
    console.log(`----- Mount`);
    fetchData();
  }, []);

  const viewCourse = async () => {
    navigate("/admin/courseEditor");
  };

  const createNewActivity = async () => {
    try {
      console.log(`--- Creating new activity`);

      // Add activity
      const addCourseResponse = await axios.post(`${serverUrl}/activity/editor/add`);

      if (addCourseResponse && addCourseResponse.data && addCourseResponse.data.success) {
        console.log(`----- Added new activity`);

        fetchData();
      }
    } catch (err) {
      console.error(`ERR: Could not add activity!`);
    }
  };

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching activities`);

      // Loading course section
      const activityOverview = await axios.get(`${serverUrl}/activity/list?includeDrafts=true`);

      if (activityOverview && activityOverview.data && activityOverview.data.activityGroups) {
        let groups = activityOverview.data.activityGroups;
        console.log(`----- Fetched and set activities`);
        let activities = [];

        if (typeof groups === "object" && groups !== null) {
          Object.keys(groups).map((activityGroup) => {
            if (activityGroup && groups[activityGroup] && groups[activityGroup].length > 0) {
              groups[activityGroup] = sortBy(groups[activityGroup], (item) => {
                return item.title;
              });

              groups[activityGroup].forEach((activity) => {
                activities.push(activity);
              });
            }
          });
        }

        setActivities(activities);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch course!`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row>
        <Col lg={6}>
          <h1 className={"mb-3"}>Activity Editor</h1>

          {activities && activities.length > 0
            ? activities.map((activity) => {
                return (
                  <div key={activity._id} className={"mb-3"}>
                    <a
                      href={`/#/admin/activityItemEditor?id=${activity.activityId}`}
                      style={{ color: activity.draft ? "grey" : null }}
                    >
                      {activity.title} ({activity.activityType}){" "}
                      {activity.premium ? <span title="Premium">*</span> : null}
                    </a>
                  </div>
                );
              })
            : null}
        </Col>
      </Row>

      <Row>
        <Col>
          <Button onClick={createNewActivity} variant="outline-primary" style={{ marginRight: 10 }}>
            ADD ACTIVITY
          </Button>
        </Col>
      </Row>
    </EditorWrapper>
  );
}

export default ActivityEditor;
