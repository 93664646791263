import "../styles/global.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { serverUrl } from "./editor/utility";
import { Chart as GoogleChart } from "react-google-charts";
import { format } from "date-fns";
import styles from "./report.module.scss";
import pippinLogo from "../images/pippin-purple.png";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function Report() {
  const { innerWidth: width, innerHeight: height } = window;
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  let dataFetched = false;
  const chartColorPalette = [
    // purple
    "#714cfd",
    // green
    "#06D6A0",
    // blue
    "#30c5ff",
  ];

  useEffect(() => {
    async function fetchReportData() {
      try {
        const token = searchParams.get("t");
        console.log(`--- Fetching data with token: ${token}`);

        const response = await axios.post(`${serverUrl}/wordTracker/token`, {
          token,
        });

        if (response && response.data && response.data.wordTracker) {
          setData(response.data);

          console.log(JSON.stringify(response.data.chartData));
        } else {
          console.log(`Bad response`);
        }
      } catch (err) {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.errorCode === "TOKEN_INVALID"
        ) {
          setError(true);
        } else {
          console.error(`ERR: Failed to fetch data via token. ${err}`);
        }
      }
    }

    if (dataFetched !== true) {
      console.log(`--- Fetching wordTracking data!`);
      fetchReportData();
      dataFetched = true;
    }
  }, []);

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px 30px",
        }}
      >
        <h1 className={styles.reportH1}>Report Unavailable</h1>
        <br />

        <p>
          This report is no longer available, please contact the person who sent this to you for a
          new link.
        </p>

        <p>For privacy reasons each report will automatically expire after 48 hours.</p>
      </div>
    );
  }

  if (data && data.wordTracker?.words && data.wordTracker?.words.length > 0) {
    return (
      <div
        style={{
          padding: "30px 30px",
        }}
      >
        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
          <img src={pippinLogo} className={styles.parrot} />
          <div className={styles.websiteLink}>
            <a href="https://www.pippinspeech.com">www.pippinspeech.com</a>
          </div>
        </div>

        <h1 className={styles.reportH1}>SPEECH REPORT</h1>

        <div>
          This report was produced by Pippin on {format(new Date(), "dd/MM/yy")} using our speech
          tracking tools.
        </div>

        <div style={{ marginTop: 15 }}>
          Want to share this report?
          <br />
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
              maxWidth: "400px",
              textOverflow: "ellipsis",
            }}
          >
            <a href={window.location.href}>Report link</a>
          </div>
        </div>

        <hr />

        <h2>{data.name}'s Speech</h2>

        <div>
          Total words: {data.wordTracker?.words.length}
          <br />
          {data.wordsPerWeek > 0 ? (
            <div>
              Avg no of words learnt each week: {Number(data.wordsPerWeek).toFixed(1)}
              <br />
            </div>
          ) : null}
          {data.wordsPerMonth > 0 ? (
            <div>Avg no of words learnt each month: {Number(data.wordsPerMonth).toFixed(1)}</div>
          ) : null}
          {data.gestureTracker?.gestures?.length > 0 ? (
            <div>Total gestures: {data.gestureTracker?.gestures?.length}</div>
          ) : null}
        </div>

        <hr />

        {data.name &&
        data.chartData &&
        typeof data.chartData === "object" &&
        data.chartData.length > 0 ? (
          <div style={{ pageBreakAfter: "always" }}>
            <h2>Word Categories</h2>

            <p>{data.name}'s Top 5</p>

            <div>
              <GoogleChart
                chartType="ColumnChart"
                width="100%"
                height="280px"
                data={data.chartData}
                options={{
                  legend: "none",
                  // eslint-disable-next-line no-restricted-globals
                  //chartArea: { left: 30, top: 30, width: innerWidth - 50, height: 130 },
                  colors: chartColorPalette,
                }}
                style={{
                  maxWidth: "500px",
                }}
              />
            </div>

            <hr />
          </div>
        ) : null}

        <div
          style={
            // If a lot of words added a page break when printing
            data.chartData.length > 10 ? { pageBreakAfter: "always" } : {}
          }
        >
          <h2>Words Tracked</h2>

          <ul>
            {data.wordTracker?.words?.length > 0
              ? data.wordTracker.words.map((word) => {
                  return <li key={word.value}>{word.value}</li>;
                })
              : null}
          </ul>
        </div>

        <div style={{ marginTop: 30 }}>
          <h2>Gestures Tracked</h2>

          <ul>
            {data.gestureTracker?.gestures?.length > 0
              ? data.gestureTracker.gestures.map((gesture) => {
                  return <li key={gesture.value}>{gesture.value}</li>;
                })
              : null}
          </ul>
        </div>

        <div
          style={{
            marginTop: 30,
            fontSize: 12,
          }}
        >
          <p>
            PRIVACY: For privacy reasons this report will expire 48 hours after the link was
            created.
            <br />
            Track your child's speech progress for free:{" "}
            <a href="https://www.pippinspeech.com" target="_blank">
              www.pippinspeech.com
            </a>
          </p>
        </div>
      </div>
    );
  }

  return null;
}

export default Report;
function useWindowDimensions() {
  throw new Error("Function not implemented.");
}
