import "../styles/global.scss";
import upgradeCourse from "../images/upgrade_course.png";
import Feature from "../components/feature";
import Footer from "../components/footer";
import React, { useEffect, useState } from "react";
import styles from "./upgrade.module.scss";
import variables from "../styles/utils.module.scss";
import pippinLogo from "../images/pippin-logo-parrot.png";
import { getParameterByName, serverUrl } from "./utility";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UpgradeFeatureWapper from "../components/featureWrapper/upgradeFeatureWrapper";

function Upgrade() {
  let navigate = useNavigate();
  const email = getParameterByName("email");
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function onOfferSubmit(data) {
    onSubmit({ promoCode: "SPEECH", ...data });
  }

  async function onSubmit(data) {
    try {
      console.log(data);

      const response = await axios.get(
        `${serverUrl}/checkout-session?email=${
          typeof email === "string" ? encodeURIComponent(email) : ""
        }&promoCode=${data.promoCode || ""}`
      );

      if (response) {
        setSubmitted(true);

        if (response.data?.url) {
          console.log(`URL is: ${response.data.url}`);
          window.location.href = response.data.url;
        }
      }
    } catch (err) {
      console.error(`ERR: Could not request content. ${err}`);
      alert(`Sorry our checkout is currently unavailable.`);
    }
  }

  return (
    <div className="Landing">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
            <img src={upgradeCourse} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0, textAlign: "left" }}>
            Speech & Communication Course
          </h1>

          <h2 className={`${styles.subtitle} h5`} style={{ marginTop: 15 }}>
            Learn five evidence based strategies to help develop your child's communication skills.
            Available on iOS and Android.
          </h2>

          <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 20 }}>
            <input type="submit" className={"buttonGreen"} value="BUY NOW" />
          </form>
        </div>
      </div>

      <UpgradeFeatureWapper onSubmit={handleSubmit(onSubmit)} />

      <Footer />
    </div>
  );
}

export default Upgrade;
