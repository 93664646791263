import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { serverUrl } from "./utility";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/editor/navigation";
import EditorWrapper from "../../components/editor/wrapper";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function Images() {
  const [images, setImages] = useState(null);
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  let navigate = useNavigate();

  // on-mount
  useEffect(() => {
    console.log(`----- Mount`);
    fetchData();
  }, []);

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching images`);

      const imagesResponse = await axios.get(`${serverUrl}/admin/images`);

      if (imagesResponse && imagesResponse.data && imagesResponse.data.images) {
        let images = imagesResponse.data.images.filter((image) => {
          return (
            image &&
            (image.url.includes("png") ||
              image.url.includes("jpg") ||
              image.url.includes("jpeg") ||
              image.url.includes("gif") ||
              image.url.includes("pdf") ||
              image.url.includes("webp"))
          );
        });

        console.log(`----- Fetched and set images`);
        setImages(images);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch course!`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row>
        <Col lg={6}>
          <h1 className={"mb-3"}>Images</h1>
        </Col>
      </Row>
      <Row>
        {images && images.length > 0
          ? images.map((image) => {
              // Check URL exists but exclude PDF's
              if (image && image.url && image.url.includes(".pdf") === false) {
                return (
                  <Col xs={6} md={2} key={image.url}>
                    <img src={image.url} style={{ height: 130 }} />
                    <p style={{ marginTop: 10, marginBottom: 10, fontSize: 10 }}>{image.url}</p>
                  </Col>
                );
              }

              // PDF's
              if (image && image.url && image.url.includes(".pdf") === true) {
                return (
                  <Col xs={6} md={2} key={image.url}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 130,
                        backgroundColor: "#ededed",
                      }}
                    >
                      <h1>PDF</h1>
                    </div>
                    <p style={{ marginTop: 10, marginBottom: 10, fontSize: 10 }}>{image.url}</p>
                  </Col>
                );
              }
            })
          : null}
      </Row>
    </EditorWrapper>
  );
}

export default Images;
