import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import MaterialIcon, { colorPalette } from "material-icons-react";
import { setStaffUser, setUser } from "../../store/user";
import axios from "axios";
import { serverUrl } from "../editor/utility";
import { useNavigate } from "react-router-dom";
import styles from "./signUpPartner.module.scss";
import learningLetters from "../../images/learning-letters.png";
import pippinParrot from "../../images/pippin-logo-parrot.png";
import pippinLogoImg from "../../images/pippin-purple.png";
import onlineShop from "../../images/online-shop.png";
import you from "../../images/user.png";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function SignUpPartner() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  let navigate = useNavigate();

  // WARN: When editing use the ngrok domain as localhost will be blocked!
  async function onSubmit(data) {
    try {
      if (!data.partnerTerms) {
        alert(`Please accept our Partner Terms and Conditions.`);
        return false;
      }
      if (data.username && data.companyName && data.password) {
        const partner = {
          name: data?.tradingName,
          legalName: data?.companyName,
          website: data?.website,
          email: data?.username,
          requestDataSharing: data?.dataSharing,
        };

        const user = {
          name: data?.personalName,
          username: data?.username,
          email: data?.username,
          password: data?.password,
          userType: "partner",
          signUpPlatform: "web",
        };

        console.log(`Submitting partnership form...`);

        axios
          .post(`${serverUrl}/partner`, { partner, user })
          .then(async (response) => {
            console.log(JSON.stringify(response.data));

            // Persist to redux store, redirection then handled by componentDidUpdate (redux store update!)
            if (response && response.data && response.data.success) {
              const { user, token } = response.data;
              console.log(`--- User is: ${user?._id}`);

              localStorage.setItem("token", token);
              dispatch(setUser(user));
              navigate("/partner/home");
            } else {
              console.info(`ERR: Could not register. ${JSON.stringify(response.data)}`);
            }
          })
          .catch((error) => {
            console.error(`Unable to login, server error. ${JSON.stringify(error)}`);

            if (error.response && error.response.data && error.response.data.message) {
              alert(error.response.data.message);
            } else {
              console.log(error.response);
            }
          });
      } else {
        console.error(`Invalid form, should be impossible with validation rules.`);
      }
    } catch (err) {
      console.error(`ERR: onSubmit error. ${err}`);
    }
  }

  return (
    <>
      <Container fluid className={`no-padding no-margin container-fluid`}>
        <Row>
          <Col lg={6} xl={7} className={styles.loginMarketing}>
            <img src={learningLetters} className={styles.learningLetters} />
            <h2 className={styles.marketingText}>Register as a Partner.</h2>
          </Col>

          <Col lg={6} xl={5} className={`${styles.loginArea}`}>
            {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
            <form onSubmit={handleSubmit(onSubmit)} className={styles.loginForm}>
              <div>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}>
                  <img src={onlineShop} style={{ width: 20, height: 20, marginRight: 10 }} />
                  <h3 className={`h3`}>Your Business</h3>
                </div>
                <div className={"mb-3"}>
                  <label htmlFor={"companyName"}>Legal name</label>
                  <div style={{ fontSize: "12px", lineHeight: "16px", marginBottom: 5 }}>
                    <span style={{ marginRight: 3, marginTop: "3px" }}>
                      <MaterialIcon icon="info_outline" size={11} />
                    </span>
                    The name we should use in contracts or payments.
                  </div>
                  <input
                    {...register("companyName")}
                    className="form-control"
                    placeholder={`ABC Ltd.`}
                    required
                    autoFocus
                  />
                </div>
                <div className={"mb-3"}>
                  <label htmlFor={"tradingName"}>Trading name</label>
                  <div style={{ fontSize: "12px", lineHeight: "16px", marginBottom: 5 }}>
                    <span style={{ marginRight: 3, marginTop: "3px" }}>
                      <MaterialIcon icon="info_outline" size={11} />
                    </span>
                    Used within the Pippin app when referring to you.
                  </div>
                  <input
                    {...register("tradingName")}
                    className="form-control"
                    placeholder={`ABC Speech`}
                    required
                  />
                </div>
                <div className={"mb-3"}>
                  <label htmlFor={"website"}>Website</label>
                  <div style={{ fontSize: "12px", lineHeight: "16px", marginBottom: 5 }}>
                    <span style={{ marginRight: 3, marginTop: "3px" }}>
                      <MaterialIcon icon="info_outline" size={11} />
                    </span>
                    Optional. The app can include a link to your website.
                  </div>
                  <input {...register("website")} className="form-control" />
                </div>
                <div className={"mb-3"}>
                  <Form.Check // prettier-ignore
                    type={`checkbox`}
                    id={`sharing-checkbox`}
                    label={`View user progress`}
                    {...register("dataSharing")}
                  />

                  <div style={{ fontSize: "12px", lineHeight: "16px", marginBottom: 5 }}>
                    <span style={{ marginRight: 3, marginTop: "3px" }}>
                      <MaterialIcon icon="info_outline" size={11} />
                    </span>
                    <u>SLT and Healthcare professionals</u> only. <br />
                    If a user accepts, we can share progress data with you.
                  </div>
                </div>
              </div>

              <div style={{ marginTop: 30 }}>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}>
                  <img src={you} style={{ width: 20, height: 20, marginRight: 10 }} />
                  <h3 className={`h3`}>About You</h3>
                </div>

                <div className={"mb-3"}>
                  <label htmlFor={"personalName"}>Your first name</label>
                  <input {...register("personalName")} className="form-control" required />
                </div>

                <div className={"mb-3"}>
                  <label htmlFor={"username"}>Email address</label>
                  <input {...register("username")} className="form-control" required />
                </div>

                <div className={"mb-3"}>
                  <label htmlFor={"password"}>Password</label>
                  <div style={{ fontSize: "12px", lineHeight: "16px", marginBottom: 5 }}>
                    <span style={{ marginRight: 3, marginTop: "3px" }}>
                      <MaterialIcon icon="info_outline" size={11} />
                    </span>
                    Minimum 6 characters.
                  </div>
                  <input
                    {...register("password")}
                    type="password"
                    className="form-control"
                    required
                    minLength={6}
                  />
                </div>
              </div>

              <div style={{ marginTop: "15px" }}>
                <div className={"mb-3"}>
                  <Form.Check // prettier-ignore
                    type={`checkbox`}
                    id={`partner-terms-checkbox`}
                    label={
                      <span>
                        I agree to the{" "}
                        <a href={"/#/partner-terms"} target="_blank" rel="noopener noreferrer">
                          partner terms and conditions.
                        </a>
                      </span>
                    }
                    {...register("partnerTerms")}
                  />
                </div>
                <input className="form-control" type="submit" value={`Register`} />
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SignUpPartner;
