import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "./utility";
import { useNavigate } from "react-router-dom";
import EditorWrapper from "../../components/editor/wrapper";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function ActivityItemEditor() {
  const activityId = getParameterByName("id");
  const [activity, setActivity] = useState(null);
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  let navigate = useNavigate();
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
  const originalActivityFields = [
    {
      id: "activityType",
      type: "text",
      placeholder: `ONLY "books", "games", "songs", "nurseryRhymes", "allAboutMe", "signs" or "resource"`,
      label: "Type",
      // returns true if OK
      validate: (text) => {
        return [
          "books",
          "games",
          "songs",
          "nurseryRhymes",
          "allAboutMe",
          "signs",
          "resource",
        ].includes(text);
      },
    },
    { id: "title", type: "text", placeholder: "", label: "Title" },
    { id: "subtitle", type: "text", placeholder: "", label: "Subtitle" },
    { id: "active", type: "text", placeholder: "", label: "Active", defaultValue: "true" },
    { id: "premium", type: "text", placeholder: "", label: "Premium", defaultValue: "true" },
    { id: "draft", type: "text", placeholder: "", label: "Draft", defaultValue: "true" },
    { id: "hidden", type: "text", placeholder: "", label: "Hide?", defaultValue: "false" },
    { id: "imageURL", type: "text", placeholder: "", label: "Image URL" },
    { id: "imageAttribution", type: "text", placeholder: "", label: "Image Attribution" },
    { id: "videoURL", type: "text", placeholder: "", label: "Video URL" },
    { id: "downloadURL", type: "text", placeholder: "", label: "Download URL" },
    { id: "timeInvolved", type: "text", placeholder: "5 minutes", label: "Time involved" },
    { id: "rating", type: "number", placeholder: "10", label: "Rating between 1 and 10" },
    { id: "promoteCourse", type: "text", placeholder: "", label: "Promote course? (true/false)" },
    { id: "tags", type: "text", placeholder: "Home,Play", label: "Tags, comma sepaated" },
  ];
  const [activityFields, setActivityFields] = useState(originalActivityFields);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  // Callback version of watch.  It's your responsibility to unsubscribe when done.
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const field = activityFields.find((item) => {
        return item.id === name;
      });

      if (field && typeof field.validate === "function") {
        const validatedOK = field.validate(value[name]);

        if (validatedOK !== true) {
          setError(name, { type: "custom", message: "Invalid activity type" });
        } else {
          clearErrors();
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  async function removeActivity() {
    try {
      console.log(`----- Removing section: ${activityId}`);

      // Remove course data
      const removeResponse = await axios.delete(
        `${serverUrl}/activity/editor/remove/${activityId}`
      );

      if (removeResponse && removeResponse.data && removeResponse.data.success) {
        navigate(-1);
      }
    } catch (err) {
      console.error(`ERR: Could not remove activity!`);
    }
  }

  // Get the speech strategy input fields and convert back into an object for storing
  function processFormFields(data) {
    /*
          id: `formattedContent-value-${contentIndex}`,
          id: `formattedContent-textprops-${contentIndex}`,
    */
    try {
      if (typeof data === "object") {
        let formattedContent = [];
        const TITLE_PREFIX = "formattedContent-value";
        const TEXTPROPS_PREFIX = "formattedContent-textprops";

        Object.keys(data).map((fieldKey) => {
          // WARN: Do not change field naming!
          // loop over titles ONLY, ignore the content field else would duplicate
          // MIN: 3 characters in title, else will skip
          if (
            fieldKey &&
            fieldKey.includes(TITLE_PREFIX) &&
            data[fieldKey] &&
            data[fieldKey].length >= 3
          ) {
            const strategyIndex = fieldKey.split(`${TITLE_PREFIX}-`)[1];
            console.log(`Index is ${strategyIndex}`);

            let textPropsObject = null;
            try {
              textPropsObject = JSON.parse(data[`${TEXTPROPS_PREFIX}-${strategyIndex}`]);
            } catch (err) {
              console.error(`ERR: Bad JSON text props. ${err}`);
            }

            formattedContent.push({
              value: data[`${TITLE_PREFIX}-${strategyIndex}`],
              textProps: textPropsObject,
            });

            // Ignore raw fields now processed
            delete data[`${TITLE_PREFIX}-${strategyIndex}`];
            delete data[`${TEXTPROPS_PREFIX}-${strategyIndex}`];
          }
        });

        let links = [];
        const LINK_NAME_PREFIX = "link-name";
        const LINK_URL_PREFIX = "link-url";

        Object.keys(data).map((fieldKey) => {
          // WARN: Do not change field naming!
          if (
            fieldKey &&
            fieldKey.includes(LINK_NAME_PREFIX) &&
            data[fieldKey] &&
            data[fieldKey].length >= 3
          ) {
            const linkIndex = fieldKey.split(`${LINK_NAME_PREFIX}-`)[1];
            console.log(`Link index is ${linkIndex}`);

            links.push({
              name: data[`${LINK_NAME_PREFIX}-${linkIndex}`],
              URL: data[`${LINK_URL_PREFIX}-${linkIndex}`],
            });

            // Ignore raw fields now processed
            delete data[`${LINK_NAME_PREFIX}-${linkIndex}`];
            delete data[`${LINK_URL_PREFIX}-${linkIndex}`];
          }
        });

        data.formattedContent = formattedContent;
        data.links = links;
        return data;
      }
    } catch (err) {
      console.error(`ERR: Could not parse formattedContent fields #8923478321894`);
    }
  }

  async function onSubmit(data) {
    try {
      console.log(`----- Fetching section: ${activityId}`);
      const processedData = processFormFields(data);

      //  "books", "games", "songs", "nurseryRhymes", "allAboutMe" or "resource"`
      if (
        typeof data.activityType === "undefined" ||
        data.activityType === "books" ||
        data.activityType === "games" ||
        data.activityType === "songs" ||
        data.activityType === "nurseryRhymes" ||
        data.activityType === "allAboutMe" ||
        data.activityType === "resource" ||
        data.activityType === "signs"
      ) {
        console.log(`--- Activity type is valid`);
      } else {
        alert(
          `Invalid Activity Type. Should be "books", "games", "songs", "nurseryRhymes", "allAboutMe", "signs" or "resource"`
        );

        return false;
      }

      // Update activity
      processedData.activityId = activityId;
      const sectionResponse = await axios.put(`${serverUrl}/activity/editor`, data);

      if (sectionResponse && sectionResponse.data && sectionResponse.data.success) {
        console.log(`----- Updated activity`);
        navigate(-1);
      } else {
        alert(`Could not save.`);
      }
    } catch (err) {
      console.error(`ERR: Could update activity!`);
    }
  }

  // on-mount
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    reloadFormData();
  }, [activity]);

  function reloadFormData() {
    if (typeof activity === "undefined" || activity === null) {
      return false;
    }

    const newCourseFields = [...originalActivityFields];

    if (activity.formattedContent && activity.formattedContent.length > 0) {
      console.log(
        `----- Processing ${activity.formattedContent.length} formatted content sections`
      );

      // DANGER: DO NOT EDIT speechstrategy- naming format, this is parsed on backend when saving
      activity.formattedContent.map((content, contentIndex) => {
        newCourseFields.push({
          id: `formattedContent-value-${contentIndex}`,
          type: "textarea",
          placeholder: "",
          label: `Content Value #${contentIndex}`,
        });

        newCourseFields.push({
          id: `formattedContent-textprops-${contentIndex}`,
          type: "text",
          placeholder: "",
          label: `Content Style #${contentIndex}`,
        });
      });
    }

    if (activity.links && activity.links.length > 0) {
      activity.links.map((link, linkIndex) => {
        newCourseFields.push({
          id: `link-name-${linkIndex}`,
          type: "text",
          placeholder: "",
          label: `Link Title #${linkIndex}`,
          value: link.name,
        });

        newCourseFields.push({
          id: `link-url-${linkIndex}`,
          type: "text",
          placeholder: "",
          label: `Link URL #${linkIndex}`,
          value: link.URL,
        });
      });
    }

    setActivityFields(newCourseFields);

    setValue("activityId", activity.activityId);
    setValue("imageURL", activity.imageURL);
    setValue("activityType", activity.activityType);
    setValue("title", activity.title);
    setValue("subtitle", activity.subtitle);
    setValue("active", activity.active);
    setValue("premium", activity.premium);
    setValue("promoteCourse", activity.promoteCourse);
    setValue("draft", activity.draft);
    setValue("hidden", activity.hidden);
    setValue("imageAttribution", activity.imageAttribution);
    setValue("videoURL", activity.videoURL);
    setValue("downloadURL", activity.downloadURL);
    setValue("timeInvolved", activity.timeInvolved);
    setValue("rating", activity.rating);
    setValue("tags", activity.tags);

    if (activity.formattedContent && activity.formattedContent.length > 0) {
      // DANGER: DO NOT EDIT speechstrategy- naming format, this is parsed on backend when saving
      activity.formattedContent.map((content, contentIndex) => {
        setValue(`formattedContent-value-${contentIndex}`, content.value);
        setValue(`formattedContent-textprops-${contentIndex}`, JSON.stringify(content.textProps));
      });
    }

    if (activity.links && activity.links.length > 0) {
      activity.links.map((link, linkIndex) => {
        setValue(`link-name-${linkIndex}`, link.name);
        setValue(`link-url-${linkIndex}`, link.URL);
      });
    }
  }

  function removeSectionProtector() {
    setShowRemovalConfirmation(true);
  }

  function addLink() {
    const newActivity = { ...activity };

    let links = newActivity.links || [];

    links.push({
      name: "",
      URL: "",
    });

    newActivity.links = links;
    setActivity(newActivity);
  }

  function addFormattedContent() {
    const newActivitySection = { ...activity };

    let formattedContent = activity.formattedContent || [];

    formattedContent.push({
      value: "",
      textProps: "",
    });

    newActivitySection.formattedContent = formattedContent;

    setActivity(newActivitySection);
  }

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching section: ${activityId}`);

      // Loading course section
      const activityResponse = await axios.get(`${serverUrl}/activity/id?activityId=${activityId}`);

      if (activityResponse && activityResponse.data && activityResponse.data.activity) {
        let activity = activityResponse.data.activity;
        console.log(`----- Fetched and set activity state`);

        setActivity(activity);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch activity!`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row className={"mb-5"}>
        <Col lg={8} xl={6}>
          <h1 className={"mb-3"}>Activity Editor</h1>

          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {activityFields && activityFields.length > 0
              ? activityFields.map((field) => {
                  return (
                    <div key={field.id} className={"mb-3"}>
                      <label htmlFor={field.id}>{field.label}</label>

                      {field.type === "text" || field.type === "number" ? (
                        <input
                          placeholder={field.placeholder}
                          {...register(field.id)}
                          className="form-control"
                          defaultValue={field.defaultValue}
                        />
                      ) : null}

                      {field.type === "textarea" ? (
                        <textarea
                          placeholder={field.placeholder}
                          {...register(field.id)}
                          className="form-control"
                          rows="10"
                        />
                      ) : null}

                      {errors[field.id] ? (
                        <p className={"input-error"}>{errors[field.id]?.message}</p>
                      ) : null}
                    </div>
                  );
                })
              : null}

            <div className="mt-2">
              <input className="form-control" type="submit" value="SAVE" />
            </div>

            <div className="mt-4 mb-5">
              <h3>Other options</h3>
              <Button onClick={addLink} variant="outline-secondary" style={{ marginRight: 10 }}>
                ADD LINK
              </Button>
              <Button
                onClick={addFormattedContent}
                variant="outline-secondary"
                style={{ marginRight: 10 }}
              >
                ADD CONTENT
              </Button>
              <Button onClick={removeSectionProtector} variant="outline-secondary">
                REMOVE
              </Button>
            </div>
          </form>
        </Col>
      </Row>

      <Modal
        show={showRemovalConfirmation}
        onHide={() => {
          setShowRemovalConfirmation(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will delete the activity... are you really sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowRemovalConfirmation(false);
            }}
          >
            Close
          </Button>
          <Button variant="danger" onClick={removeActivity}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </EditorWrapper>
  );
}

export default ActivityItemEditor;
