import "../../styles/global.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialIcon, { colorPalette } from "material-icons-react";
import Table from "react-bootstrap/Table";
import { setUser } from "../../store/user";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "../utility";
import { useNavigate } from "react-router-dom";
import PartnerWrapper from "../../components/partner/wrapper";
import styles from "./partnerHome.module.scss";
import { format } from "date-fns";

function PartnerReport() {
  const organisationId = getParameterByName("organisationId");
  const childName = getParameterByName("childName");
  const user = useSelector((state) => state.userStore.user);
  const partner = useSelector((state) => state.partnerStore.partner);
  const [report, setReport] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.partnerId) {
      console.error(`User is NOT a partner`);
      navigate("/");
    }
  }, [user]);

  function getSpeechAssessment({ reportRaw }) {
    if (reportRaw && reportRaw.assessment && reportRaw.answers) {
      const assessmentReport = reportRaw.assessment;

      assessmentReport.categories.forEach((category) => {
        category.questions.forEach((question) => {
          if (
            reportRaw.answers.answers &&
            reportRaw.answers.answers[category.categoryId] &&
            reportRaw.answers.answers[category.categoryId][question._id]
          ) {
            question.answer = reportRaw.answers.answers[category.categoryId][question._id]?.value;
          } else {
            console.error(`No answer found for: ${category.categoryId} - ${question._id}`);
          }
        });
      });

      // Date assessment completed
      assessmentReport.created = reportRaw.answers?.created;
      assessmentReport.dob = reportRaw.organisationInfo?.dob;
      assessmentReport.name = reportRaw.organisationInfo?.name;

      return assessmentReport;
    }
  }

  const openReport = useCallback(async ({ organisationId }) => {
    try {
      if (organisationId) {
        console.log(`Trying to fetch assessment data for org: ${organisationId}`);
        const response = await axios.get(
          `${serverUrl}/partner/assessment?organisationId=${organisationId}`,
          {
            organisationId,
          }
        );

        const reportRaw = response?.data;
        const assessmentReport = getSpeechAssessment({ reportRaw });
        setReport(assessmentReport);
      }
    } catch (err) {
      console.error(`ERR: Report could not be opened. ${err}`);
    }
  }, []);

  useEffect(() => {
    if (!report && organisationId) {
      console.log(`Loading report`);
      openReport({ organisationId });
    }
  }, [report, organisationId]);

  const noReportFound = useCallback(() => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          height: "60vh",
        }}
      >
        <h3>No reports found</h3>
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </div>
      </div>
    );
  }, []);

  const renderSpeechReport = useCallback(() => {
    return (
      <div style={{ marginBottom: 90 }}>
        <h2>{report.title}</h2>

        <div style={{ display: "flex", height: 20, paddingBottom: 30 }}>
          <div
            style={{
              marginRight: 15,
              paddingRight: 15,
              borderRight: "1px solid #bebebe",
            }}
          >
            Child: {report?.name}
          </div>

          <div
            style={{
              marginRight: 15,
              paddingRight: 15,
              borderRight: "1px solid #bebebe",
            }}
          >
            DOB: {typeof report?.dob === "string" ? format(new Date(report.dob), "dd/MM/yy") : ""}
          </div>

          <div>
            Submitted:{" "}
            {typeof report?.created === "string"
              ? format(new Date(report.created), "dd/MM/yy")
              : ""}
          </div>
        </div>

        {report.categories.map((category, categoryIndex) => {
          return (
            <div style={{ marginTop: 30, pageBreakAfter: categoryIndex === 2 ? "always" : "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
                className={styles.categoryWrapper}
              >
                {category?.imageURL && category?.imageURL.includes("https") ? (
                  <img src={category.imageURL} style={{ width: 18, height: 18, marginRight: 8 }} />
                ) : null}

                <h4 style={{ lineHeight: "20px", fontSize: "18px", marginBottom: 0 }}>
                  {category.title}
                </h4>
              </div>

              {category?.questions.map((question) => {
                return (
                  <div style={{ marginTop: 15 }}>
                    <div>{question.title}</div>
                    <div className={styles.answer}>{question.answer}</div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }, [report]);

  return (
    <PartnerWrapper>
      <Row className={""}>
        <Col lg={12}>
          <div style={{ marginTop: 30, marginLeft: 15 }}>
            {report ? renderSpeechReport() : noReportFound()}
          </div>
        </Col>
      </Row>
    </PartnerWrapper>
  );
}

export default PartnerReport;
