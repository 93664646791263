import "../../styles/global.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialIcon, { colorPalette } from "material-icons-react";
import Table from "react-bootstrap/Table";
import { setUser } from "../../store/user";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "../utility";
import { useNavigate } from "react-router-dom";
import PartnerWrapper from "../../components/partner/wrapper";
import styles from "./partnerHome.module.scss";
import { setPartner, setReferrals } from "../../store/partner";
import balloonLots from "../../images/balloonsLots.png";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function PartnerHome() {
  const user = useSelector((state) => state.userStore.user);
  const partner = useSelector((state) => state.partnerStore.partner);
  const referrals = useSelector((state) => state.partnerStore.referrals);
  const [referalCommissionsMap, setReferalCommissionsMap] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.partnerId) {
      console.error(`User is NOT a partner`);
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    async function getCommissions(userIdList) {
      console.log(userIdList);
      const commissions = await axios.post(`${serverUrl}/referral/commission`, {
        userIdList,
      });

      if (commissions.data && commissions.data.userPayments) {
        console.log(`commissions: ${JSON.stringify(commissions.data.userPayments)}`);
        setReferalCommissionsMap(commissions.data.userPayments);
      }
    }

    if (referrals && referalCommissionsMap === null) {
      const userIdMap = {};

      referrals.forEach((referral) => {
        userIdMap[referral._id] = null;
      });

      try {
        const userIdList = Object.keys(userIdMap);
        if (userIdList && userIdList.length > 0) {
          getCommissions(userIdList);
        }
      } catch (err) {
        console.error(`ERR: Report could not be opened. ${err}`);
      }
    }
  }, [referrals]);

  const openReport = useCallback(async ({ organisationId, childName }) => {
    try {
      if (organisationId) {
        console.log(`Navigating to report for: ${organisationId}`);
        navigate(`/partner/report?organisationId=${organisationId}&childName=${childName}`);
      }
    } catch (err) {
      console.error(`ERR: Report could not be opened. ${err}`);
    }
  }, []);

  const openWordTracker = useCallback(async ({ organisationId, childName }) => {
    try {
      if (organisationId) {
        const partnerToken = await axios.get(
          `${serverUrl}/partner/tracker/token?organisationId=${organisationId}`
        );

        navigate(`/report?t=${partnerToken.data?.token}`);
      }
    } catch (err) {
      console.error(`ERR: Report could not be opened. ${err}`);
    }
  }, []);

  return (
    <PartnerWrapper>
      <Row className={""}>
        <Col lg={12} className={styles.referralTable}>
          {referrals && referrals.length === 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                height: "50vh",
              }}
            >
              <img src={balloonLots} style={{ width: 150, height: 150, marginBottom: 30 }} />

              <h2>Welcome to Pippin!</h2>

              <p style={{ textAlign: "center" }}>
                Invite people to Pippin using your unique referral link:
                <br />
                <a href={partner?.referralLink}>{partner?.referralLink}</a>
              </p>

              <p style={{ textAlign: "center" }}>
                Interested in offering Pippin Premium to your customers?
                <br />
                <a href="mailto:licensing@pippinspeech.com?Subject=Licensing%20Pippin">
                  Contact us
                </a>{" "}
                for discounted licensing options.
              </p>
            </div>
          ) : null}

          {referrals && referrals.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Email or ID</th>
                  <th style={{ minWidth: 200 }}>Child</th>
                  <th style={{ minWidth: 130 }}>Data Sharing</th>
                  <th style={{ minWidth: 150 }}>
                    <div
                      title="Commission in USD over the last 30 days"
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      Commission <MaterialIcon icon="info_outline" size={14} />
                    </div>
                  </th>
                  <th style={{ minWidth: 100 }}>Reports</th>
                </tr>
              </thead>
              <tbody>
                {referrals.map((referral) => {
                  return (
                    <tr key={referral?._id}>
                      <td>{referral.email || referral?._id}</td>
                      <td>
                        {referral?.organisationInfo?.name
                          ? `${referral?.organisationInfo?.name} (${new Date(
                              referral?.organisationInfo?.dob
                            )?.toLocaleDateString()})`
                          : "N/A"}
                      </td>
                      <td>
                        {referral?.organisationInfo?.partnerDataSharing ? "Shared" : "Private"}
                      </td>
                      <td>
                        {referalCommissionsMap && referalCommissionsMap[referral?._id]
                          ? `$${parseFloat(
                              referalCommissionsMap[referral?._id]?.usdCommission
                            ).toFixed(2)}`
                          : "$0"}
                      </td>
                      <td>
                        {referral?.organisationInfo && referral?.organisationInfo?._id ? (
                          <>
                            <span
                              onClick={() => {
                                openReport({
                                  organisationId: referral?.organisationInfo?._id,
                                  childName: referral?.organisationInfo?.name,
                                });
                              }}
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                                marginRight: 10,
                              }}
                              title="Speech Assessment"
                            >
                              <MaterialIcon icon="notes" size={18} color={"#000"} />
                            </span>
                            <span
                              onClick={async () => {
                                openWordTracker({
                                  organisationId: referral?.organisationInfo?._id,
                                  childName: referral?.organisationInfo?.name,
                                });
                              }}
                              style={{ textDecoration: "underline", cursor: "pointer" }}
                              title="Word and Gesture Tracker"
                            >
                              <MaterialIcon icon="timeline" size={18} color={"#000"} />
                            </span>
                          </>
                        ) : (
                          <div>N/A</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : null}
        </Col>
      </Row>
    </PartnerWrapper>
  );
}

export default PartnerHome;
