import "../styles/global.scss";
import mockup from "../images/pippinmockup1.png";
import axios from "axios";
import progress from "../images/progress.png";
import chat from "../images/chat.png";
import learn from "../images/learn.png";
import React, { useState } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import { useForm } from "react-hook-form";
import pippinLogo from "../images/pippin-logo-parrot.png";
import Feature from "../components/feature";
import { getParameterByName, serverUrl } from "./utility";

function ActivityContentGate() {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const activityId = getParameterByName("activityId");
  const title = getParameterByName("title");

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const response = await axios.post(`${serverUrl}/user/marketing/content`, {
        activityId,
        ...data,
        referrer: document.referrer,
      });

      if (response) {
        setSubmitted(true);
      }
    } catch (err) {
      console.error(`ERR: Could not request content`);
      alert(`Error, the content you requested is not available.`);
    }
  };

  return (
    <div className="Gate">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ position: "relative" }}>
            <img src={mockup} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0 }}>
            {title || "Download Activity"}
          </h1>

          <p style={{ color: "white", marginTop: 15 }}>
            Get a free activity to try at home, just enter your email address below.
            <br />
            We'll also add you to our mailing list for more resources and offers.
          </p>

          {submitted !== true ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 15,
                paddingBottom: 30,
              }}
            >
              <div style={{ marginRight: 10 }}>
                <input
                  type="text"
                  placeholder="Email address"
                  {...register("email", { required: true })}
                />
                {errors.email && <p>Email is required.</p>}
              </div>

              <div>
                <input type="submit" value="SEND PDF" />
              </div>
            </form>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 15,
                paddingBottom: 30,
                color: "#ffffff",
              }}
            >
              Thanks, check your email, a link is on it's way!
              <br />
              Why not Download Pippin now to find more great ideas?
            </div>
          )}
        </div>
      </div>

      <Feature imagePath={chat} reverse={false} imageHeight={500} imageWidth={500}>
        <h3>Why are activities important?</h3>

        <p>
          Learning to speak is all about practice and your child will learn best when it's with you!
          Speech activities like songs, games and books are great at keeping your child engaged and
          often use repetition and simple phrases.
        </p>

        <p>
          Download Pippin (Launching August 2022) to see the full list of activities we've created
          with the help of expert speech therapists.
        </p>
      </Feature>

      <div className={styles.benefits}>
        <div className={styles.benefitItem}>
          <div className={styles.benefitItemImage}>
            <img src={learn} width={80} className={styles.benefitItemImageSrc} />
          </div>

          <div className={styles.benefitItemText}>
            1. Speech therapy training for parents to use anywhere.
          </div>
        </div>

        <div className={styles.benefitItem}>
          <div className={styles.benefitItemImage}>
            <img src={chat} width={80} className={styles.benefitItemImageSrc} />
          </div>

          <div className={styles.benefitItemText}>
            2. Includes easy to set-up games and activities to encourage speech
          </div>
        </div>

        <div className={styles.benefitItem}>
          <div className={styles.benefitItemImage}>
            <img src={progress} width={80} className={styles.benefitItemImageSrc} />
          </div>

          <div className={styles.benefitItemText}>
            3. Review speech progress and track words on the go
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityContentGate;
