import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "./utility";
import { useNavigate } from "react-router-dom";
import EditorWrapper from "../../components/editor/wrapper";
import { useSelector } from "react-redux";

function AssessmentItemEditor() {
  const assessmentId = getParameterByName("id");
  const [assessment, setAssessment] = useState(null);
  let navigate = useNavigate();
  const [showRemovalConfirmation, setShowRemovalConfirmation] = useState(false);
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  const originalAssessmentFields = [
    { id: "title", type: "text", placeholder: "", label: "Title" },
    { id: "subtitle", type: "text", placeholder: "", label: "Subtitle" },
    { id: "active", type: "text", placeholder: "", label: "Active", defaultValue: "true" },
    { id: "draft", type: "text", placeholder: "", label: "Draft", defaultValue: "true" },
    { id: "premium", type: "text", placeholder: "", label: "Premium", defaultValue: "true" },
    {
      id: "recommendedAgeInMonths",
      type: "number",
      placeholder: "18",
      label: "Recommended Age in Months",
    },
    { id: "imageURL", type: "text", placeholder: "", label: "Image URL" },
  ];
  const [assessmentFields, setAssessmentFields] = useState(originalAssessmentFields);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  async function removeAssessment() {
    try {
      console.log(`----- Removing assessment: ${assessmentId}`);

      // Remove assessment data
      const removeResponse = await axios.delete(
        `${serverUrl}/assessment/editor/remove/${assessmentId}`
      );

      if (removeResponse && removeResponse.data && removeResponse.data.success) {
        navigate(-1);
      }
    } catch (err) {
      console.error(`ERR: Could not remove assessment!`);
    }
  }

  // TODO
  // Get the assessment input fields and convert back into an object for storing
  function processFormFields(data) {
    try {
      if (typeof data === "object") {
        let questions = [];
        const TITLE_PREFIX = "questions-value";
        const TEXTPROPS_PREFIX = "questions-textprops";

        Object.keys(data).map((fieldKey) => {
          // WARN: Do not change field naming!
          // loop over titles ONLY, ignore the content field else would duplicate
          // MIN: 3 characters in title, else will skip
          if (
            fieldKey &&
            fieldKey.includes(TITLE_PREFIX) &&
            data[fieldKey] &&
            data[fieldKey].length >= 3
          ) {
            const strategyIndex = fieldKey.split(`${TITLE_PREFIX}-`)[1];
            console.log(`Index is ${strategyIndex}`);

            let textPropsObject = null;
            try {
              textPropsObject = JSON.parse(data[`${TEXTPROPS_PREFIX}-${strategyIndex}`]);
            } catch (err) {
              console.error(`ERR: Bad JSON text props. ${err}`);
            }

            questions.push({
              value: data[`${TITLE_PREFIX}-${strategyIndex}`],
              textProps: textPropsObject,
            });

            // Ignore raw fields now processed
            delete data[`${TITLE_PREFIX}-${strategyIndex}`];
            delete data[`${TEXTPROPS_PREFIX}-${strategyIndex}`];
          }
        });

        data.questions = questions;
        return data;
      }
    } catch (err) {
      console.error(`ERR: Could not parse formattedContent fields #8923478321894`);
    }
  }

  async function onSubmit(data) {
    try {
      console.log(`----- Fetching assessment: ${assessmentId}`);
      const processedData = processFormFields(data);

      // Update assessment
      processedData.assessmentId = assessmentId;
      const assessmentResponse = await axios.put(`${serverUrl}/assessment/editor`, data);

      if (assessmentResponse && assessmentResponse.data && assessmentResponse.data.success) {
        console.log(`----- Updated assessment`);
        navigate(-1);
      } else {
        alert(`Could not save.`);
      }
    } catch (err) {
      console.error(`ERR: Could not update assessment!`);
    }
  }

  // on-mount
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    reloadFormData();
  }, [assessment]);

  function reloadFormData() {
    if (typeof assessment === "undefined" || assessment === null) {
      return false;
    }

    const newCourseFields = [...originalAssessmentFields];

    if (assessment.formattedContent && assessment.formattedContent.length > 0) {
      console.log(`----- Processing ${assessment.formattedContent.length} questions`);

      // DANGER: DO NOT EDIT speechstrategy- naming format, this is parsed on backend when saving
      assessment.questions.map((content, contentIndex) => {
        newCourseFields.push({
          id: `questions-value-${contentIndex}`,
          type: "textarea",
          placeholder: "",
          label: `Content Value #${contentIndex}`,
        });

        newCourseFields.push({
          id: `questions-textprops-${contentIndex}`,
          type: "text",
          placeholder: "",
          label: `Content Style #${contentIndex}`,
        });
      });
    }

    setAssessmentFields(newCourseFields);

    setValue("assessmentId", assessment.assessmentId);
    setValue("title", assessment.title);
    setValue("subtitle", assessment.subtitle);
    setValue("active", assessment.active);
    setValue("draft", assessment.draft);
    setValue("premium", assessment.premium);
    setValue("recommendedAgeInMonths", assessment.rating);
    setValue("imageURL", assessment.imageURL);

    if (assessment.questions && assessment.questions.length > 0) {
      // DANGER: DO NOT EDIT speechstrategy- naming format, this is parsed on backend when saving
      assessment.questions.map((question, questionIndex) => {
        setValue(`questions-value-${questionIndex}`, question.value);
        setValue(`questions-textprops-${questionIndex}`, JSON.stringify(question.textProps));
      });
    }
  }

  function removeSectionProtector() {
    setShowRemovalConfirmation(true);
  }

  function addQuestion() {
    const newAssessmentQuestion = { ...assessment };

    let questions = assessment.questions || [];

    questions.push({
      value: "",
      textProps: "",
    });

    newAssessmentQuestion.questions = questions;

    setAssessment(newAssessmentQuestion);
  }

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching assessment: ${assessmentId}`);

      // Loading course section
      const response = await axios.get(`${serverUrl}/assessment/id?assessmentId=${assessmentId}`);

      if (response && response.data && response.data.assessment) {
        let assessment = response.data.assessment;
        console.log(`----- Fetched and set assessment state`);

        setAssessment(assessment);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch assessment!`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row className={"mb-5"}>
        <Col lg={8} xl={6}>
          <h1 className={"mb-3"}>Assessment Editor</h1>

          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {assessmentFields && assessmentFields.length > 0
              ? assessmentFields.map((field) => {
                  return (
                    <div key={field.id} className={"mb-3"}>
                      <label htmlFor={field.id}>{field.label}</label>

                      {field.type === "text" || field.type === "number" ? (
                        <input
                          placeholder={field.placeholder}
                          {...register(field.id)}
                          className="form-control"
                          defaultValue={field.defaultValue}
                        />
                      ) : null}

                      {field.type === "textarea" ? (
                        <textarea
                          placeholder={field.placeholder}
                          {...register(field.id)}
                          className="form-control"
                          rows="10"
                        />
                      ) : null}
                    </div>
                  );
                })
              : null}

            <div className="mt-2">
              <input className="form-control" type="submit" value="SAVE" />
            </div>

            <div className="mt-4 mb-5">
              <h3>Other options</h3>
              <Button onClick={addQuestion} variant="outline-secondary" style={{ marginRight: 10 }}>
                ADD QUESTION
              </Button>
              <Button onClick={removeSectionProtector} variant="outline-secondary">
                REMOVE
              </Button>
            </div>
          </form>
        </Col>
      </Row>

      <Modal
        show={showRemovalConfirmation}
        onHide={() => {
          setShowRemovalConfirmation(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This will delete the assessment... are you really sure?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowRemovalConfirmation(false);
            }}
          >
            Close
          </Button>
          <Button variant="danger" onClick={removeAssessment}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </EditorWrapper>
  );
}

export default AssessmentItemEditor;
