import "../../styles/global.scss";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialIcon, { colorPalette } from "material-icons-react";
import Table from "react-bootstrap/Table";
import { setUser } from "../../store/user";
import { Row, Col, Button, Modal, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { getParameterByName, serverUrl } from "../utility";
import { useNavigate } from "react-router-dom";
import PartnerWrapper from "../../components/partner/wrapper";
import styles from "./partnerHome.module.scss";
import { setPartner } from "../../store/partner";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function PartnerSettings() {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();
  const user = useSelector((state) => state.userStore.user);
  const partner = useSelector((state) => state.partnerStore.partner);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.partnerId) {
      console.error(`User is NOT a partner`);
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    setValue("logoUrl", partner?.logoUrl);
    setValue("website", partner?.website);
    setValue("twitterHandle", partner?.twitterHandle);
    setValue("instagramHandle", partner?.instagramHandle);
    setValue("alertPreference", partner?.alertPreference);
    setValue("requestDataSharing", partner?.requestDataSharing === true ? "yes" : "no");
  }, [partner]);

  // WARN: When editing use the ngrok domain as localhost will be blocked!
  async function onSubmit(data) {
    try {
      const updateBody = {};

      if (data.website) {
        updateBody.website = data.website;
      }
      if (data.logoUrl) {
        updateBody.logoUrl = data.logoUrl;
      }
      if (data.twitterHandle) {
        updateBody.twitterHandle = data.twitterHandle;
      }
      if (data.instagramHandle) {
        updateBody.instagramHandle = data.instagramHandle;
      }
      if (data.alertPreference) {
        updateBody.alertPreference = data.alertPreference;
      }
      if (typeof data.requestDataSharing === "string") {
        updateBody.requestDataSharing = data.requestDataSharing === "yes" ? true : false;
      }

      if (data) {
        console.log(`Updating partner info...`);

        axios
          .put(`${serverUrl}/partner`, updateBody)
          .then(async (response) => {
            console.log(JSON.stringify(response.data));

            // Persist to redux store, redirection then handled by componentDidUpdate (redux store update!)
            if (response && response.data && response.data.success) {
              const { user, token } = response.data;
              dispatch(setPartner(partner));
              navigate("/partner/home");
            } else {
              console.info(`ERR: Could not update partner. ${JSON.stringify(response.data)}`);
            }
          })
          .catch((error) => {
            console.error(`Unable to update partner, server error. ${JSON.stringify(error)}`);

            if (error.response && error.response.data && error.response.data.message) {
              alert(error.response.data.message);
            } else {
              console.log(error.response);
            }
          });
      } else {
        console.error(`Invalid form, should be impossible with validation rules.`);
      }
    } catch (err) {
      console.error(`ERR: onSubmit error. ${err}`);
    }
  }

  return (
    <PartnerWrapper>
      <Row className={""}>
        <Col lg={12}>
          <div className={styles.mainAreaPadded}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.loginForm}>
              <div>
                <h2>Settings</h2>
                <p>
                  Your partner organisation is {partner?.legalName}.
                  <br />
                  You are logged in as {user?.email}.
                </p>
              </div>

              <div style={{ marginTop: 30, maxWidth: "90%", width: 500 }}>
                <div style={{ marginBottom: 10 }}>
                  <div style={{ fontWeight: "bold" }}>Webite</div>
                  <input
                    {...register("website")}
                    className="form-control"
                    placeholder={`https://`}
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <div style={{ fontWeight: "bold" }}>Logo</div>
                  <input
                    {...register("logoUrl")}
                    className="form-control"
                    placeholder={`https://`}
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <div style={{ fontWeight: "bold" }}>Instagram Handle</div>
                  <input
                    {...register("instagramHandle")}
                    className="form-control"
                    placeholder={`@`}
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <div style={{ fontWeight: "bold" }}>Twitter Handle</div>
                  <input
                    {...register("twitterHandle")}
                    className="form-control"
                    placeholder={`@`}
                  />
                </div>

                <div style={{ marginBottom: 20 }}>
                  <div style={{ fontWeight: "bold" }}>Communication Preferences</div>

                  <select className="form-control" {...register("alertPreference")}>
                    <option value="always">Always</option>
                    <option value="daily">Daily</option>
                    <option value="never">Never</option>
                  </select>
                </div>

                <div style={{ marginBottom: 20 }}>
                  <div style={{ fontWeight: "bold" }}>Request Data Sharing</div>

                  <select className="form-control" {...register("requestDataSharing")}>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>

                <div style={{ marginTop: "10px" }}>
                  <input className="form-control" type="submit" value={`Update`} />
                </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </PartnerWrapper>
  );
}

export default PartnerSettings;
