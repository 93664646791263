import "../styles/global.scss";
import upgradeCourse from "../images/upgrade_course.png";
import success from "../images/success.png";
import train from "../images/playing-train.jpg";
import checklist from "../images/checklist.png";
import Feature from "../components/feature";
import Footer from "../components/footer";
import React, { useEffect, useState } from "react";
import styles from "./upgrade.module.scss";
import variables from "../styles/utils.module.scss";
import pippinLogo from "../images/pippin-logo-parrot.png";
import gem from "../images/gem.png";
import money from "../images/money.png";
import { getParameterByName, serverUrl } from "./utility";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UpgradeFeatureWapper from "../components/featureWrapper/upgradeFeatureWrapper";

function UpgradeOffer() {
  let navigate = useNavigate();
  const email = getParameterByName("email");
  const promoCode = getParameterByName("promoCode");
  const [submitted, setSubmitted] = useState(false);
  const saleBanner = false;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  async function onOfferSubmit(data) {
    onSubmit({ promoCode: promoCode || "FIVEOFF", ...data });
  }

  async function onSubmit(data) {
    try {
      console.log(data);

      const response = await axios.get(
        `${serverUrl}/checkout-session?email=${
          typeof email === "string" ? encodeURIComponent(email) : ""
        }&promoCode=${data.promoCode || ""}`
      );

      if (response) {
        setSubmitted(true);

        if (response.data?.url) {
          console.log(`URL is: ${response.data.url}`);
          window.location.href = response.data.url;
        }
      }
    } catch (err) {
      console.error(`ERR: Could not request content. ${err}`);
      alert(`Sorry our checkout is currently unavailable.`);
    }
  }

  return (
    <div className="Landing">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
            <img src={upgradeCourse} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          {saleBanner ? (
            <div
              style={{
                marginBottom: 15,
                display: "flex",
                flexDirection: "row",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 10,
                  fontWeight: "bold",
                  backgroundColor: "black",
                  padding: "4px 12px",
                }}
              >
                BLACK FRIDAY SALE
              </div>
            </div>
          ) : null}

          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0, textAlign: "left" }}>
            Speech & Communication Course
          </h1>

          <h2 className={`${styles.subtitle} h5`} style={{ marginTop: 15 }}>
            Learn five evidence based strategies to help develop your child's communication skills.
            Available on iOS and Android.
          </h2>

          {promoCode ? (
            <div style={{ color: "#cecece" }}>Discount code applied: '{promoCode}'</div>
          ) : null}

          <form onSubmit={handleSubmit(onOfferSubmit)} style={{ marginTop: 20 }}>
            <input type="submit" className={"buttonGreen"} value="BUY NOW" />
          </form>
        </div>
      </div>

      <UpgradeFeatureWapper includeCourseCTA={false} onSubmit={null} />

      <Footer />
    </div>
  );
}

export default UpgradeOffer;
