import "../styles/global.scss";
import mockup from "../images/pippinmockup1.png";
import axios from "axios";
import progress from "../images/progress.png";
import chat from "../images/chat.png";
import learn from "../images/learn.png";
import React, { useState } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import { useForm } from "react-hook-form";
import pippinLogo from "../images/pippin-logo-parrot.png";
import { serverUrl } from "./utility";

function Unsubscribed() {
  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div className="Unsubscribed">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={styles.landingHeader}>
        <div className={styles.left}>
          <div style={{ position: "relative" }}>
            <img src={mockup} className={styles.headerImage} />
          </div>
        </div>
        <div className={styles.right}>
          <h1 style={{ color: variables.offWhite, margin: 0, marginBottom: 0 }}>Unsubscribed</h1>

          <h2 className={`${styles.subtitle} h5`} style={{ marginTop: 15 }}>
            You will not receive further marketing emails from Pippin.
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Unsubscribed;
