import { createSlice } from "@reduxjs/toolkit";

// https://react-redux.js.org/tutorials/quick-start
export const courseStore = createSlice({
  name: "course",
  initialState: {
    sections: null,
    nextSection: null,
  },
  reducers: {
    setCourseSections: (state, action) => {
      state.sections = action.payload;
    },
    setNextSection: (state, action) => {
      state.nextSection = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCourseSections, setNextSection } = courseStore.actions;

export default courseStore.reducer;
