import "../styles/global.scss";
import mockup from "../images/preview-pippin-ios.png";
import axios from "axios";
import progress from "../images/progress.png";
import chat from "../images/chat.png";
import learn from "../images/learn.png";
import React, { useEffect, useState } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import { useForm } from "react-hook-form";
import pippinLogo from "../images/pippin-purple.png";
import Feature from "../components/feature";
import Footer from "../components/footer";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="About">
        <a href={`/`}>
          <img src={pippinLogo} className={styles.parrot} />
        </a>

        <div className={`container mainMarginTop`} style={{ marginBottom: 50 }}>
          <div className="row">
            <div className="col-sm-1 col-lg-2 col-xl-3"></div>

            <div className="col-sm-10 col-lg-8 col-xl-6">
              <h1 style={{ margin: 0, marginBottom: 40, textAlign: "center" }}>About Pippin</h1>

              <div style={{ display: "flex", justifyContent: "center", marginBottom: 50 }}>
                <img src={mockup} style={{ height: 300 }} />
              </div>

              <p>
                <b>Pippin is an app for parents of speech delayed children</b>. The app provides
                ideas for activities to try at home, games, speech strategies and other resources in
                one place.
              </p>

              <p>
                The founders of Pippin, Kieran and Alice, have a son called Leo who at the age of
                2.5 years was officially recognised as speech delayed. There was a delay in getting
                him assessed, with long waiting lists for Speech and Language services.
              </p>

              <p>
                Whilst waiting for the assessment they researched, read books and scoured the
                internet for as much information on speech delay as possible. To their surprise
                there was no one place which offered support to parents on what they could be doing
                at home to help Leo with his speech. It took months and months of researching until
                they developed a series of assets which they used with Leo at home to help him with
                his speech and language development.
              </p>

              <p>
                Leo was initially assessed as having the speech of a child a year younger. He was
                assessed again at 3 years old and was considered “caught up” although towards the
                lower end of his peers. As parents, Alice and Kieran realised just how important all
                of the speech games, activities and reading were in helping Leo catch up.
              </p>
              <p>
                Pippin was created to help other parents in this situation not feel so lost or alone
                as they once felt. Many of the resources in the app are free to use along with links
                to other specialist information we recommend. Premium features, such as our speech
                strategy course, come with a free trial so you can see what you think before
                continuing.
              </p>

              <p>
                <b>Update</b>: We are thrilled to announce that Kate Burgess has joined our founding
                team. With 15 years of clinical experience as a Speech & Language Therapist, Kate
                brings a wealth of knowledge and expertise to Pippin.
                <br />
                Kate has created our fantastic speech and communication course, packed with great
                new content, and leads our monthly Q&A sessions. On top of managing our product
                content, Kate is also a Director of the business.
              </p>
            </div>

            <div className="col-sm-1 col-lg-2 col-xl-3"></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default About;
