import "../../styles/global.scss";
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { serverUrl } from "./utility";
import { useNavigate } from "react-router-dom";
import EditorWrapper from "../../components/editor/wrapper";
import { useSelector } from "react-redux";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function AssessmentEditor() {
  const [assessments, setAssessments] = useState(null);
  const isStaffUser = useSelector((state) => state.userStore.isStaffUser);
  let navigate = useNavigate();

  // on-mount
  useEffect(() => {
    console.log(`----- Mount`);
    fetchData();
  }, []);

  const createNewAssessment = async () => {
    try {
      console.log(`--- Creating new assessment`);

      // Add assessment
      const addCourseResponse = await axios.post(`${serverUrl}/assessment/editor/add`);

      if (addCourseResponse && addCourseResponse.data && addCourseResponse.data.success) {
        console.log(`----- Added new assessment`);

        fetchData();
      }
    } catch (err) {
      console.error(`ERR: Could not add assessment!`);
    }
  };

  // Note: Using internal async function as useEffect cannot itself be async
  const fetchData = async () => {
    try {
      console.log(`----- Fetching assessment`);

      // Loading assessment list
      const assessments = await axios.get(`${serverUrl}/assessment/list?includeDrafts=true`);

      if (assessments && assessments.data && assessments.data.assessmentList) {
        let assessmentList = assessments.data.assessmentList;
        setAssessments(assessmentList);
      }
    } catch (err) {
      console.error(`ERR: Could not fetch assessmentList!`);
    }
  };

  return (
    <EditorWrapper isStaffUser={isStaffUser}>
      <Row>
        <Col lg={6}>
          <h1 className={"mb-3"}>Assessments</h1>

          {assessments && assessments.length > 0
            ? assessments.map((assessment) => {
                return (
                  <div key={assessment._id} className={"mb-3"}>
                    <a
                      href={`/#/admin/assessmentItemEditor?id=${assessment.assessmentId}`}
                      style={{ color: assessment.draft ? "grey" : null }}
                    >
                      {assessment.title}{" "}
                      {assessment.premium ? <span title="Premium">*</span> : null}
                    </a>
                  </div>
                );
              })
            : null}
        </Col>
      </Row>

      <Row>
        <Col>
          <Button
            onClick={createNewAssessment}
            variant="outline-primary"
            style={{ marginRight: 10 }}
          >
            ADD ASSESSMENT
          </Button>
        </Col>
      </Row>
    </EditorWrapper>
  );
}

export default AssessmentEditor;
