import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user";
import courseReducer from "./course";
import organisationReducer from "./organisation";
import partnerReducer from "./partner";

export default configureStore({
  reducer: {
    userStore: userReducer,
    courseStore: courseReducer,
    organisationStore: organisationReducer,
    partnerStore: partnerReducer,
  },
});
