import "../styles/global.scss";
import mockup from "../images/pippinmockup1.png";
import axios from "axios";
import progress from "../images/progress.png";
import chat from "../images/chat.png";
import learn from "../images/learn.png";
import React, { useState } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import { useForm } from "react-hook-form";
import pippinLogo from "../images/pippin-purple.png";
import Feature from "../components/feature";

function Privacy() {
  return (
    <div className="Privacy">
      <a href={`/`}>
        <img src={pippinLogo} className={styles.parrot} />
      </a>

      <div className={`container mainMarginTop`} style={{ marginBottom: 60 }}>
        <div className="row">
          <div className="col-sm-1 col-lg-2 col-xl-3"></div>

          <div className="col-sm-10 col-lg-8 col-xl-6">
            <h1 style={{ margin: 0, marginBottom: 15 }}>Privacy Policy</h1>
            <p>
              Family Timeline Ltd. (also known as "MySunshine") is the company that created Pippin.
              <br />
              <br />
              Your privacy is important to us. It is MySunshine's policy to respect your privacy
              regarding any information we may collect from you through our app, Pippin.
              <br />
              <br />
              We only ask for personal information when we truly need it to provide a service to
              you. We collect it by fair and lawful means, with your knowledge and consent. We also
              let you know why we're collecting it and how it will be used. <br />
              <br />
              We only retain collected information for as long as necessary to provide you with your
              requested service. What data we store, we'll protect within commercially acceptable
              means to prevent loss and theft, as well as unauthorised access, disclosure, copying,
              use or modification. <br />
              <br />
              We don't share any personally identifying information publicly or with third-parties,
              except when required to by law. We will not sell personal or sensitive user data to
              anyone, anywhere.
              <br />‍<br />
              Our app may link to external sites that are not operated by us. Please be aware that
              we have no control over the content and practices of these sites, and cannot accept
              responsibility or liability for their respective privacy policies.
              <br />‍<br />
              You are free to refuse our request for your personal information, with the
              understanding that we may be unable to provide you with some of your desired services.
              <br />‍<br />
              Your continued use of our app will be regarded as acceptance of our practices around
              privacy and personal information. If you have any questions about how we handle user
              data and personal information, feel free to contact us.
              <br />
              <br />
              This policy is effective as of 29th June 2022.
              <br />
              <br />
              <strong>Accounts:</strong>
              <br />
              When you create an account with us, you must provide us information that is accurate,
              complete, and current at all times. Failure to do so constitutes a breach of the
              Terms, which may result in immediate termination of your account on our Service.
              <br />
              You are responsible for safeguarding the password that you use to access the Service
              and for any activities or actions under your password, whether your password is with
              our Service or a third-party service. <br />
              <br />
              You agree not to disclose your password to any third party. You must notify us
              immediately upon becoming aware of any breach of security or unauthorized use of your
              account.
              <br />‍<br />
              We will store any personal data securely and within the EU at all times, you give us
              permission to store and process any information you enter. It is your responsibility
              to request permission from your clients regarding their personal information. Data may
              be be stored for up to 1 year after your account is deleted, unless otherwise stated
              or required by law.
              <br />‍<br />
              When signing up using either Apple or Google authentication methods, we will securely
              store the data that is passed to us. This will include a unique identifier and usually
              your full name and/or email address.
              <br />
              <br />
              <strong>Third party software:</strong>
              <br />
              Any links to third-party software or integrations available on this website are
              provided “as is” without warranty of any kind, either expressed or implied and such
              software is to be used at your own risk. Additionally, we make no warranty that, the
              third-party software will meet your requirements, quality, security or service level
              expectations. Third party software providers we work with include Google, Amazon AWS,
              and Apple. This is not an exhaustive list.
              <br />
              <br />
              <strong>Data access, Collection, Use, and Sharing:</strong>
              <br />
              You can sign up and register using your Google or Apple account if you wish.
              MySunshine will collect and store basic information including your name and email
              address. This is so we can create an account for you and securely log you into Pippin.{" "}
              <br />‍<br />
              <br />‍<br />
            </p>
          </div>

          <div className="col-sm-1 col-lg-2 col-xl-3"></div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
