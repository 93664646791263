import "../styles/global.scss";
import React, { useState } from "react";

// Render server side and embed as a <WebView />
// https://www.react-google-charts.com/components/chart
function Blog() {
  const { innerWidth: width, innerHeight: height } = window;

  return (
    <div
      style={{
        padding: "30px 30px",
      }}
    >
      <h1>Child Development</h1>
      <p style={{ fontStyle: "italic" }}>Time: 10 minutes</p>
      <br />

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing pharetra porttitor amet
        massa in nibh. Aliquam pretium, hendrerit id elementum convallis ultrices enim urna.
        Pharetra vitae pellentesque id id scelerisque tortor eu pulvinar. Congue facilisis morbi
        ante arcu. Dolor gravida facilisis rutrum maecenas nunc nulla. Amet id elit sodales iaculis
        consequat. Vitae id lectus tempor porta. Sagittis sit volutpat enim auctor dictumst ut
        bibendum lectus volutpat. Nunc, arcu vestibulum consectetur mauris senectus pharetra ipsum,
        a, sit. Convallis fringilla eget et, diam. Curabitur eget sollicitudin ornare tortor,
        commodo erat consectetur et. Vel eleifend at phasellus scelerisque ultricies adipiscing.
        Duis fringilla urna egestas lorem purus erat. Vulputate accumsan lectus risus nulla magna
        feugiat mauris in. Sit lacus vitae lacus consequat massa tristique mi orci. Pretium pretium
        sit nunc arcu porttitor ut. Dui egestas euismod accumsan quam quisque. In et sapien pulvinar
        risus morbi. Vitae etiam ante blandit in. Libero pretium nulla quis nulla proin. Nunc luctus
        dolor iaculis sollicitudin. Vel mauris, nulla interdum dignissim. Sed non id habitant amet
        vulputate suscipit vestibulum vitae faucibus. Quam sed elementum lorem facilisi.
      </p>
    </div>
  );
}

export default Blog;
