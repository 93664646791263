import "../styles/global.scss";
import mockup from "../images/preview-pippin-ios.png";
import axios from "axios";
import progress from "../images/progress.png";
import chat from "../images/chat.png";
import learn from "../images/learn.png";
import React, { useEffect, useState } from "react";
import styles from "./landing.module.scss";
import variables from "../styles/utils.module.scss";
import { useForm } from "react-hook-form";
import pippinLogo from "../images/pippin-purple.png";
import Feature from "../components/feature";
import Footer from "../components/footer";

function Deletion() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="Deletion">
        <a href={`/`}>
          <img src={pippinLogo} className={styles.parrot} />
        </a>

        <div className={`container mainMarginTop`} style={{ marginBottom: 50 }}>
          <div className="row">
            <div className="col-sm-1 col-lg-2 col-xl-3"></div>

            <div className="col-sm-10 col-lg-8 col-xl-6">
              <h1 style={{ margin: 0, marginBottom: 40, textAlign: "center" }}>Account Deletion</h1>

              <div style={{ display: "flex", justifyContent: "center", marginBottom: 50 }}>
                <img src={mockup} style={{ height: 300 }} />
              </div>

              <p>
                <b>Delete your account from within the app</b>. The app provides functionality to
                request your account is deleted immediately and without any further verification
                steps required (Recommended).
                <br /> <br />
                Steps: Please go to the Settings tab and select 'Account', then the 'Delete Account'
                button and confirm.
              </p>

              <p>
                If you wish to delete your account but cannot login, we recommend you reset your
                password or contact us for help so that you can delete from within the app.
              </p>

              <p>
                If you do not wish to use the app to delete your account please instead contact us
                by email, deletion@pippinspeech.com. Please include: Your name and associated email
                address (this must match). If you logged in with iOS we may not know your email
                address and additional steps may be required.
              </p>
            </div>

            <div className="col-sm-1 col-lg-2 col-xl-3"></div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Deletion;
